.tableScroll {
    @include breakpoint(medium up) {
        [name="email-description"] {
            @include td-col(6);
        }
    }

    @include breakpoint(large up) {
        [name="email-description"] {
            @include td-col(8);
        }
    }
}