@import '../../settings/content-settings';

/// content main styles
.mainWrapper {
    @if $content-bg != none {
        background: $content-bg;
    }
}
.middleRightWrapper{
    article{
        @include responsive-size(padding, $content-padding);
    }
}
.contentTopWrapper {
    z-index: z(contentheader) - 1;
}