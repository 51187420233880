.tableScroll {
    [name="purchaser-ic"] {
        @include td-col(3);
    }
    [name="purchaser-companyname"] {
        @include td-col(5);
    }
    [name="purchaser-_action"] {
        @include td-col(2);
    }

    @include breakpoint(small up) {
        [name="purchaser-companyname"] {
            @include td-col(9);
        }
    }

    @include breakpoint(medium up) {
        [name="purchaser-ic"] {
            @include td-col(2);
        }
        [name="purchaser-companyname"] {
            @include td-col(10);
        }
        [name="purchaser-_action"] {
            @include td-col(2);
        }
    }

    @include breakpoint(xlarge up) {
        [name="purchaser-_action"] {
            @include td-col(1);
        }
    }
}