@function getMapDiv($map, $divider, $rem-calc: true) {
    $coll : ();
    @each $size, $value in $map {
        $result: ($value / $divider);
        @if type-of($value) == 'number' and is-absolute-length($value) and $rem-calc {
            $result: rem-calc($result);
        }
        @else {
            @if unit($result) == 'px' {
                $result: round($result);
            }
        }
        $mmap: ($size: $result);
        $coll : map-merge($coll, $mmap);
    }
    @return $coll;
}
@function getMapMulti($map, $multiplier, $rem-calc: true) {
    $coll : ();
    @each $size, $value in $map {
        $result: ($value * $multiplier);
        @if type-of($value) == 'number' and is-absolute-length($value) and $rem-calc {
            $result: rem-calc($result);
        }
        @else {
            @if unit($result) == 'px' {
                $result: round($result);
            }
        }
        $mmap: ($size: $result);
        $coll : map-merge($coll, $mmap);
    }
    @return $coll;
}
@function getMapAdd($map, $sum, $rem-calc: true) {
    $coll : ();
    @each $size, $value in $map {
        $result: ($value + $sum);
        @if type-of($value) == 'number' and is-absolute-length($value) and $rem-calc {
            $result: rem-calc($result);
        }
        @else {
            @if unit($result) == 'px' {
                $result: round($result);
            }
        }
        $mmap: ($size: $result);
        $coll : map-merge($coll, $mmap);
    }
    @return $coll;
}
@function getBaseGutter($size) {
    @return round(map-get($grid-column-gutter, $size)/2);
}

@function first($list) {
    @return nth($list, 1);
}
@function last($list) {
    @return nth($list, length($list));
}
@function to-list($value, $keep: 'both') {
    $keep: if(index('keys' 'values', $keep), $keep, 'both');

    @if type-of($value) == 'map' {
        $keys: ();
        $values: ();

        @each $key, $val in $value {
            $keys: append($keys, $key);
            $values: append($values, $val);
        }

        @if $keep == 'keys' {
            @return $keys;
        } @else if $keep == 'values' {
            @return $values;
        } @else {
            @return zip($keys, $values);
        }
    }

    @return if(type-of($value) != 'list', ($value,), $value);

}

@function is-absolute-length($value) {
    @return is-number($value) and index('cm' 'mm' 'in' 'px' 'pt' 'pc', unit($value)) != null;
}

@function z($layerKey, $z-layers: $zindex-values) {
    @if not map-has-key($z-layers, $layerKey) {
        @warn "No layer found for `#{$layerKey}` in $z-layers map. Property omitted.";
    }

    @return map-deep-get($z-layers, $layerKey);
}

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}

@function url-encode($string) {
    $map: (
        "%": "%25",
        "<": "%3C",
        ">": "%3E",
        " ": "%20",
        "!": "%21",
        "*": "%2A",
        "'": "%27",
        '"': "%22",
        "(": "%28",
        ")": "%29",
        ";": "%3B",
        ":": "%3A",
        "@": "%40",
        "&": "%26",
        "=": "%3D",
        "+": "%2B",
        "$": "%24",
        ",": "%2C",
        "/": "%2F",
        "?": "%3F",
        "#": "%23",
        "[": "%5B",
        "]": "%5D"
    );
    $new: $string;
    @each $search, $replace in $map {
        $new: str-replace($new, $search, $replace);
    }
    @return $new;
}

@function inline-svg($string) {
    @return url('data:image/svg+xml;utf-8,#{url-encode($string)}');
}

@function linear-interpolation($map) {
    $keys: map-keys($map);
    @if (length($keys) != 2) {
        @error "linear-interpolation() $map must be exactly 2 values";
    }
    // The slope
    $m: (map-get($map, nth($keys, 2)) - map-get($map, nth($keys, 1)))/(nth($keys, 2) - nth($keys,1));

    // The y-intercept
    $b: map-get($map, nth($keys, 1)) - $m * nth($keys, 1);

    // Determine if the sign should be positive or negative
    $sign: "+";
    @if ($b < 0) {
        $sign: "-";
        $b: abs($b);
    }

    @return calc(#{$m*100}vw #{$sign} #{$b});
}
@function list-remove($list, $index) {
    $newList: ();
    @for $i from 1 through length($list) {
        @if $i != $index {
            $newList: append($newList, nth($list,$i), 'space');
        }
    }
    @return $newList;
}
@function list-sort($list) {
    $sortedlist: ();
    @while length($list) > 0 {
        $value: nth($list,1);
        @each $item in $list {
            @if $item < $value {
                $value: $item;
            }
        }
        $sortedlist: append($sortedlist, $value, 'space');
        $list: list-remove($list, index($list, $value));
    }
    @return $sortedlist;
}

@function map-sort($map) {
    $keys: list-sort(map-keys($map));
    $sortedMap: ();
    @each $key in $keys {
        $sortedMap: map-merge($sortedMap, ($key: map-get($map, $key)));
    }
    @return $sortedMap;
}

@function check-luminance-color-lightness($color, $lightness: 50%) {
    @if (lightness($color) > $lightness) {
        @return true;
    } @else {
        @return false;
    }
}

@function addToList($list, $value, $rem-calc: true) {
    $newList: ();
    $addValue: 0;
    @if ($rem-calc) {
        $addValue: rem-calc($value);
    }
    @else {
        @if unit($value) == 'px' {
            $addValue: strip-unit($value);
        } @else {
            $addValue: $value;
        }
    }
    @for $i from 1 through length($list) {
        $newList: append($newList, nth($list, $i) + $addValue);
    }
    @return $newList;
}