@import '../settings/components/datepicker-settings';

.datepicker {
    &.dropdown-menu {
        border-radius: $dsfe-datepicker-ui-border-radius !important;
    }
    .dow {
        background: $dsfe-datepicker-day-heading-bg;
        color: $dsfe-datepicker-day-heading-color;
    }
}

.fa-chevron-left.fi-arrow-left::after {
    @include css-triangle($dsfe-datepicker-arrow-size, $dsfe-datepicker-arrow-color, left);
}
.fa-chevron-right.fi-arrow-right::after {
    content: "";
    @include css-triangle($dsfe-datepicker-arrow-size, $dsfe-datepicker-arrow-color, right);
}

.year {
    &.active {
        color: $dsfe-datepicker-day-active-color;
        background: $dsfe-datepicker-day-active-bg;
    }
}
.month {
    &.active {
        color: $dsfe-datepicker-month-active-color;
        background: $dsfe-datepicker-month-active-bg;
    }
}
.day {
    &.active {
        color: $dsfe-datepicker-year-active-color;
        background: $dsfe-datepicker-year-active-bg;
    }
}