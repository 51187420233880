$dsfe-datepicker-arrow-size: 5px !default;
$dsfe-datepicker-arrow-color: $primary-color !default;
$dsfe-datepicker-day-active-color: white !default;
$dsfe-datepicker-month-active-color: white !default;
$dsfe-datepicker-year-active-color: white !default;
$dsfe-datepicker-day-active-bg: $primary-color !default;
$dsfe-datepicker-month-active-bg: $primary-color !default;
$dsfe-datepicker-year-active-bg: $primary-color !default;
$dsfe-datepicker-day-heading-bg: $white !default;
$dsfe-datepicker-day-heading-color: $black !default;
$dsfe-datepicker-ui-border-radius: 0px !default;
