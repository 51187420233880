.tableScroll {
    [name="payment-month"] {
        @include td-col(3);
    }
    [name="payment-personsurnameandfirstname"] {
        @include td-col(5);
    }
    [name="payment-listrequired"] {
        @include td-col(5);
    }
    [name="payment-listcosts"] {
        @include td-col(5);
    }
    [name="payment-listadvancepayments"] {
        @include td-col(5);
    }
    [name="payment-listpaymenttotal"] {
        @include td-col(5);
    }
    [name="payment-locked"] {
        @include td-col(3);
    }
    [name="payment-_action"] {
        @include td-col(2);
    }


    [name="advancepayment-payment.month"], [name="cost-month.month"] {
        @include td-col(5);
    }
    [name="advancepayment-person.surname"], [name="cost-person.surname"] {
        @include td-col(6);
    }
    [name="advancepayment-method"] {
        @include td-col(5);
    }
    [name="advancepayment-amount"], [name="cost-amount"] {
        @include td-col(4);
    }
    [name="advancepayment-lastmodification"] {
        @include td-col(5);
    }
    [name="advancepayment-_action"] {
        @include td-col(2);
    }
    [name="cost-_action"] {
        @include td-col(3);
    }

    @include breakpoint(medium up) {
        [name="payment-month"] {
            @include td-col(2);
        }
        [name="payment-personsurnameandfirstname"] {
            @include td-col(4);
        }
        [name="payment-listrequired"] {
            @include td-col(3);
        }
        [name="payment-listcosts"] {
            @include td-col(3);
        }
        [name="payment-listadvancepayments"] {
            @include td-col(3);
        }
        [name="payment-listpaymenttotal"] {
            @include td-col(3);
        }
        [name="payment-locked"] {
            @include td-col(3);
        }
        [name="payment-_action"] {
            @include td-col(2);
        }

        [name="advancepayment-payment.month"], [name="cost-month.month"] {
            @include td-col(3);
        }
        [name="advancepayment-person.surname"], [name="cost-person.surname"] {
            @include td-col(4);
        }
        [name="advancepayment-method"] {
            @include td-col(3);
        }
        [name="advancepayment-amount"], [name="cost-amount"] {
            @include td-col(3);
        }
        [name="advancepayment-lastmodification"] {
            @include td-col(4);
        }
        [name="advancepayment-_action"], [name="cost-_action"] {
            @include td-col(2);
        }
    }

    @include breakpoint(large up) {
        [name="payment-personsurnameandfirstname"] {
            @include td-col(3);
        }
        [name="payment-locked"] {
            @include td-col(2);
        }
        [name="payment-_action"] {
            @include td-col(1);
        }

        [name="advancepayment-person.surname"], [name="cost-person.surname"] {
            @include td-col(5)
        }
        [name="advancepayment-_action"] {
            @include td-col(1);
        }
    }
}