@import '../../settings/layout-settings';

html, body{
    height: 100%;
    -webkit-overflow-scrolling: auto;
}
body{
    font-family: $body-font-family;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.topBar{
    width: 100%;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
}
.mainWrapper{
    display: flex;
    flex-direction: $layout-main-wrapper-flex-direction;
    flex: 1 1 auto;
    min-height: 0; // firefox bug
    position: relative;
}
nav{
    display: flex;
    flex-direction: column;
    z-index: z(nav);
    @include offcanvas();
}
.middleRightWrapper{
    display: flex;
    flex: 1 1 0px;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    .contentHeader{
        flex: 0 0 auto;
    }
    @if ($layout-content-has-scroll) {
        @include breakpoint($layout-content-scroll-breakpoint up){
            overflow-y: auto;
            .content{
                display: flex;
                flex: 1 1 auto;
                flex-direction: $layout-content-flex-direction;
                position: relative;
                overflow: hidden;
            }
        }
    }
    @include breakpoint(large up){
        @include responsive-size(margin-left, $nav-sizes);
        .toggleLeftSidebarDesktop &{
            margin-left: 0;
        }
    }
    @include breakpoint(xxlarge up){
        .content{
            @include responsive-size(padding-right, $aside-sizes);
            &.toggleRightSidebarDesktop{
                padding-right: 0;
            }
        }
    }
}
article{
    display: block;
    flex: auto;
    @if ($layout-article-has-scroll) {
        @include breakpoint($layout-article-scroll-breakpoint up){
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }
    }
}
aside{
    display: flex;
    flex: 0 0 auto;
    @include offcanvas("right", "xxlarge", ".toggleRightSidebarMobile", ".toggleRightSidebarDesktop");
}
.footer{
    display: block;
    flex: 0 0 auto;
}