.tableScroll {
  [name*="-batch"] {
    @include td-col(2);
    @include breakpoint(large up) {
      @include td-col(1);
    }
  }
  
  [name*="action"] {
    @include td-col(5);
    @include breakpoint(medium up) {
      @include td-col(5);
    }
  }

  tr {
    &, &:last-child {
      td[name*="dailyconstructionrecord"], th[name*="dailyconstructionrecord"] {
        &, &:first-child, &:last-child {
          border-bottom: solid 1px $dark-gray;
          border-color: $dark-gray;
        }
      }
    }
  }
}