.tableScroll  {
  [name*="batch"] {
    max-width: 50px;
  }
  [name*="participant:not(batch)"] {
    @include td-col(6);
  }
  [name*="username"] {
    @include td-col(5);
  }
  [name*="title"] {
    @include td-col(8);
  }
  [name*="workplace"] {
  }
  [name="user-_action"] {
    @include td-col(3);
  }
  [name="email-active"] {
    @include td-col(3);
  }
  [name="email-_action"] {
    @include td-col(2);
  }
  [name="skill-_action"] {
    @include td-col(2);
  }
  [name="certificate-territorialvalidity"] {
    @include td-col(4);
  }
  [name="certificate-_action"] {
    @include td-col(2);
  }
  [name="category-_action"] {
    @include td-col(2);
  }
  [name="costtype-_action"] {
    @include td-col(2);
  }
  [name="toolcategory-_action"] {
    @include td-col(2);
  }

  .weekDay {
    @include td-col(2);
  }
  .tableDescription {
    @include td-col(24);
    flex-direction: column;
    align-items: flex-start;
    @extend .breakWord;
  }


  @include breakpoint(small up) {
    [name*="title"] {
      @include td-col(9);
    }
  }

  @include breakpoint(medium up) {
    [name*="participant:not(batch)"] {
      @include td-col(4);
    }
    [name*="username"] {
      @include td-col(4);
    }
    [name*="title"] {
      @include td-col(7);
    }
    [name="user-_action"] {
      @include td-col(2);
    }
    [name="email-active"] {
      @include td-col(2);
    }
    [name="email-_action"], [name="skill-_action"], [name="certificate-_action"], [name="category-_action"], [name="costtype-_action"], [name="toolcategory-_action"] {
      @include td-col(1);
    }
    [name="certificate-territorialvalidity"] {
      @include td-col(3);
    }
    .weekDay {
      @include td-col(1);
    }
  }

  @include breakpoint(large up) {
    [name*="username"] {
      @include td-col(3);
    }
  }

  @include breakpoint(xlarge up) {
    [name*="title"] {
      @include td-col(6);
    }
    [name*="workplace"] {
      @include td-col(6);
    }
    [name="user-_action"] {
      @include td-col(1.5);
    }
  }

  @include breakpoint(xxlarge up) {
    [name*="title"] {
      @include td-col(4);
    }
    [name*="workplace"] {
      @include td-col(4);
    }
    .tableDescription {
      width: auto;
    }
  }
}