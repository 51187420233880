$nav-level0-background: $white !default;
$nav-level0-shadow: 0 1px 6px $base-shadow-color !default;
$nav-level0-shadow-style: "" !default;
$nav-level0-border-right: 0 !default;
$nav-level0-item-border: 1px solid $light-gray !default;
$nav-level0-item-paddings-left:  (null: 0) !default;
$nav-level0-icon-color: $dark-gray !default;
$nav-level0-arrow-color: $primary-color !default;
$nav-level0-link-color: $body-font-color !default;
$nav-level0-font-weight: 500 !default;
$nav-level0-current-color: $secondary-color !default;
$nav-level0-current-bg: $white !default;
$nav-level0-current-font-weight: 700 !default;
$nav-level0-current-bg-hover: mix($nav-level0-current-bg, $black, 98%) !default;
$nav-level0-font-size: $base-font-sizes !default;
$nav-level0-icon-sizes: (
    null: rem-calc(18),
    medium: rem-calc(20),
) !default;
$nav-level0-arrow-sizes: (
    null: rem-calc(10),
    medium: rem-calc(12),
    large: rem-calc(14)
) !default;
$nav-level1-item-border: 0 !default;
$nav-level1-item-paddings-left: (null: 0) !default;
$nav-level1-link-color: $body-font-color !default;
$nav-level1-icon-color: $dark-gray !default;
$nav-level1-link-font-weight: 400 !default;
$nav-level1-current-color: $secondary-color !default;
$nav-level1-current-bg-hover: mix($nav-level0-current-bg, $black, 98%) !default;
$nav-level1-current-font-weight: 400 !default;
$nav-level1-font-weight: $nav-level0-font-weight !default;
$nav-level2-item-border: 0 !default;
$nav-level2-item-paddings-left: (null: 0) !default;
$nav-level2-link-color: $secondary-color !default;
$nav-level2-current-bg-hover: $nav-level1-current-bg-hover !default;
$nav-level2-current-color: $nav-level1-current-color !default;
$nav-level2-font-weight: $nav-level0-font-weight !default;
// side nav
// ---------
$side-nav-select-padding: (
    null: rem-calc(5),
    large: rem-calc(10),
) !default;

$nav-level1-font-size: (
    null: rem-calc(12),
    large: rem-calc(12),
) !default;

$nav-level2-font-size: (
    null: rem-calc(11),
    large: rem-calc(11),
) !default;

$nav-level0-link-padding: (
    null: rem-calc(12 10 12 10),
    medium: rem-calc(10 15 10 15),
    xxlarge: rem-calc(12 15 12 15),
) !default;
$nav-level1-link-padding: (
    null: rem-calc(10 10 10 30),
    medium: rem-calc(10 15 10 40),
    xxlarge: rem-calc(9 15 9 40),
) !default;
$nav-level2-link-padding: (
    null: rem-calc(8 10 8 46),
    medium: rem-calc(8 15 8 56),
    xxlarge: rem-calc(6 15 6 56),
) !default;
