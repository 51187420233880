@import '../settings/form-elements-settings';

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
select
{
    @include responsive-size(font-size, $form-field-font-sizes);
    @include responsive-size(height, $base-form-elements-heights);
    @include responsive-size(margin-bottom, $base-element-gutter-bottom);
    &:disabled, &[readonly] {
        color: $input-color-disabled;
        background-color: $select-background-disabled;
        &::placeholder {
            color: $input-placeholder-color-disabled;
        }
    }
}
textarea{
    @include responsive-size(font-size, $form-textarea-font-sizes);
    @include responsive-size(margin-bottom, $base-element-gutter-bottom);
}
label{
    @include responsive-size(font-size, $label-font-sizes);
    @include isSet($label-required-symbol){
        &.required {
            &::after {
                content: $label-required-symbol;
                color: $label-required-symbol-color;
                margin-left: $label-required-symbol-margin-left;
                @include responsive-size(font-size, $label-required-symbol-size);
            }
        }
    }
}
button {
    @include responsive-size(margin-bottom, $base-element-gutter-bottom);
}
.button{
    font-weight: $button-font-weight;
    &, .button-group &{
        @include responsive-size(font-size, $button-group-font-sizes);
        @include responsive-size(margin-bottom, $base-element-gutter-bottom);
    }
}
.switch{
    @include responsive-size(margin-bottom, $base-element-gutter-bottom);
}

.wrapper-radio, .wrapper-checkbox {
    display: flex;
    align-items: center;
    [type='radio'], [type='checkbox'] {
        margin: 0;
    }
    @include responsive-size(margin-bottom, $base-element-gutter-bottom);
}
.fieldsetHeading, .fieldsetHead, legend {
    font-weight: $legend-font-weight;
    color: $legend-color;
    text-transform: $legend-text-transform;
    background: $legend-background;
    @include responsive-size(font-size, $legend-font-sizes);
    line-height: $legend-line-height;
    @include responsive-size(margin-bottom, $legend-margin-bottom);
}
pre {
    @include responsive-size(margin-bottom, $base-element-gutter-bottom);
}