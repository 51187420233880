@import '../../settings/components/content-header-settings';

/// content header widths, padding/margins and font-sizes
$content-header-padding: (
    null: rem-calc(getBaseGutter(xsmall) getBaseGutter(xsmall) 0 getBaseGutter(xsmall)),
    medium: rem-calc(getBaseGutter(medium) getBaseGutter(medium) 0 getBaseGutter(medium)),
) !default;

$content-header-h1-margin: (
    null: rem-calc(0 0 getBaseGutter(xsmall) 0),
    medium: rem-calc(0 0 getBaseGutter(medium) 0),
) !default;

$content-header-h1-font-size: (
    xsmall: rem-calc(17),
    medium: rem-calc(20),
    xlarge: rem-calc(22)
) !default;

/// content header main styles
.contentHeader{
    background: $content-header-bg;
    color: $content-header-color;
    width: 100%;
    z-index: z(contentheader);
    border-bottom: $content-header-border;
    @if $content-header-has-shadow{
        box-shadow: $content-header-shadow;
        border-bottom: none;
    }
    @include responsive-size(padding, $content-header-padding);
    h1 {
        color: $content-header-h1-color;
        font-weight: $content-header-h1-weight;
        @include responsive-size(margin, $content-header-h1-margin);
        @include responsive-size(font-size, $content-header-h1-font-size);
    }
}