@mixin callout-arrow($horizontal-place, $vertical-place, $size, $color, $direction, $margin-left: $base-element-gutter-half) {
  &:before, &:after {
    @include responsive-size(margin-left, $margin-left);
  }
  @if $vertical-place == top {
    &:before {
      @include css-triangle($size, $color, $direction);
    }
  }
  @if $vertical-place == bottom {
    &:after {
      @include css-triangle($size, $color, $direction);
    }
  }
}

// html editor
.editor {
  @include responsive-size(padding-bottom, $base-element-gutter-half);
}

.closeButton {
  font-size: $close-button-size;
}
.calloutArrow {
  $background: scale-color($warning-color, $lightness: $callout-background-fade);
  .calloutContent {
    overflow: hidden;
    padding: rem-calc(16 16 0 16);
    background: $background;
  }
  @include callout-arrow(left, bottom, 1rem, $background, down);
}
[data-closable] {
  p {
    padding-right: rem-calc(25);
  }
}
.participants-rule {
  margin: rem-calc(0) 0 rem-calc(15) 0;
}
.participant .blockActions, .costParticipant {
  .badge, .closeButton, .loading {
    margin-bottom: 0;
  }
}
.documents {
  .header {
    &.documents-title {
      display: none;
      @include breakpoint(medium up) {
        display: block;
      }
    }
    &.documents-category {
      display: none;
      @include breakpoint(medium up) {
        display: block;
      }
    }
    &.documents-description {
      display: none;
      @include breakpoint(xlarge up) {
        display: block;
      }
    }
    &.documents-file {
      display: none;
      @include breakpoint(medium up) {
        display: block;
      }
    }
    &.documents-_delete {
      display: none;
      @include breakpoint(medium up) {
        display: block;
      }
    }
  }
  [class*="-_delete"] {
    align-self: center;
    @include flex-grid-column(24, $grid-column-gutter);
    @include breakpoint(medium up) {
      @include flex-grid-column(3, $grid-column-gutter);
    }
    @include breakpoint(xlarge up) {
      @include flex-grid-column(2, $grid-column-gutter);
    }
  }
  [class*="documents-title"] {
    @include flex-grid-column(24, $grid-column-gutter);
    @include breakpoint(medium up) {
      @include flex-grid-column(7, $grid-column-gutter);
    }
    @include breakpoint(xlarge up) {
      @include flex-grid-column(6, $grid-column-gutter);
    }
  }
  [class*="documents-category"] {
    @include flex-grid-column(24, $grid-column-gutter);
    @include breakpoint(medium up) {
      @include flex-grid-column(7, $grid-column-gutter);
    }
    @include breakpoint(xlarge up) {
      @include flex-grid-column(6, $grid-column-gutter);
    }
  }
  [class*="documents-description"] {
    display: none;
    @include breakpoint(xlarge up) {
      display: block;
      @include flex-grid-column(6, $grid-column-gutter);
    }
  }
  [class*="documents-file"] {
    align-self: center;
    @include flex-grid-column(24, $grid-column-gutter);
    @include breakpoint(medium up) {
      @include flex-grid-column(7, $grid-column-gutter);
    }
    @include breakpoint(xlarge up) {
      @include flex-grid-column(4, $grid-column-gutter);
    }
  }
}
.striped {
  @include add-striped(#f5f5f5, true);
}
.callout {
  @include responsive-size(padding-left, $base-element-gutter-half);
  @include responsive-size(padding-top, $base-element-gutter-half);
  @include responsive-size(padding-right, $base-element-gutter-half);
}
.typography-text {
  @include responsive-size(font-size, $base-font-sizes);
}

.insert-attendance {
  display: flex;
  align-items: center;
  @include responsive-size(margin-bottom, $hr-margin-sizes);
  svg {
    @include responsive-size(margin-right, $base-element-gutter-quarter);
    margin-bottom: 0;
  }
}

.highlight-row {
  background: mix($callout-background, map_get($foundation-palette, warning), $callout-background-fade) !important;
}

.headerButton {
  display: inline-block;
  position: relative;
  padding-left: $header-icons-padding-left;

  svg {
    position: absolute;
    fill: $white;
    width: $header-icons-size;
    height: $header-icons-size;
    top: 50%;
    margin-top: -($header-icons-size/2);
    left: ($header-icons-padding-left/6);
  }
}

.hyperlinkButton {
  display: inline-block;
  position: relative;
  padding-left: $header-icons-padding-left;

  svg {
    position: absolute;
    fill: map_get($foundation-palette, primary);
    width: $header-icons-size;
    height: $header-icons-size;
    top: 50%;
    margin-top: -($header-icons-size/2);
    left: ($header-icons-padding-left/6);
  }
}

.listIconButton {
  display: inline-block;
  margin-bottom: 0;
  @include responsive-size('padding-left', getMapDiv($grid-column-gutter, 4));
  @include responsive-size('padding-right', getMapDiv($grid-column-gutter, 4));
  @include responsive-size('padding-bottom', getMapDiv($grid-column-gutter, 4));
  svg {
    fill: map_get($foundation-palette, primary);
    width: $header-icons-size / 1.5;
    height: $header-icons-size / 1.5;
  }
}

.paymentDetail {
  .controlButtons {
    display: inline-block;
  }
  .paymentSummary {
    @include responsive-size(margin-top, $base-element-gutter-half);
  }
  .loading {
    display: none;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.text-alert {
  color:  map_get($foundation-palette, alert);
}

.flex-wrapper {
  display: flex;

  .callout {
    max-width: 100%;
  }
}

.iconButtons {
  .iconButtonFill {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    margin-bottom: $global-margin;
    svg {
      flex: 0 1 auto;
      width: rem-calc(30);
      height: rem-calc(30);
      @include responsive-size(margin-right, $base-element-gutter-half);
    }
    .button {
      flex: 1 1;
      margin-bottom: 0;
    }
  }
  @include breakpoint(small up) {
    .iconButtonFill {
      .button {
        flex: 0 1 auto;
      }
    }
  }
}

.hourRate {
  .striped {
    @include add-striped(#f5f5f5, false);
  }
  .controlButtons {
    text-align: center;
    form {
      display: inline-block;
    }
    button {
      margin-bottom: 0;
    }
  }
  @include breakpoint(small up) {
    .controlButtons {
      text-align: left;
    }
  }
}
.label {
  &.small {
    font-size: small;
    padding: rem-calc(5);
  }
}
@mixin projectColorsGenerator($color) {
  $customColorsPalete: map_get($customColorsPalete, $color);
  .row {
    &.highlightRow#{$color} {
      background: rgba($customColorsPalete, 0.15);
      position: relative;
      &:before{
        display: block;
        content: "";
        border-left: 3px solid $customColorsPalete;
        height: 100%;
        width: 0;
        position: absolute;
        left: -1px;
        top: 0;
      }
    }
  }
  .listTable{
    .highlightRow#{$color} {
      td{
        background: rgba($customColorsPalete, 0.15);
        &:first-child{
          border-left: 3px solid $customColorsPalete;
        }
      }
    }
  }
  .tableStateClasses {
    tr {
      &.#{$color} {
        td {
          background-color: rgba($customColorsPalete, 0.15);
        }
      }
    }
    td, th {
      &.#{$color} {
        background-color: rgba($customColorsPalete, 0.15) !important;
      }
    }
  }
}
@each $color, $value in $customColorsPalete {
  @include projectColorsGenerator($color);
}

#sticker {
  &.isSticked {
    position: fixed;
    top: 121px;
    right: 0;
    margin: 0;
    background: $white;
    box-shadow: $top-bar-shadow;
    z-index: 1;
    @include responsive-size(left, $aside-sizes);
    @include responsive-size(padding, $base-element-gutter-half);
    hr {
      display: none;
    }
  }
}

.monthlyAttendance {
  border-bottom: $table-style-border;
  tr {
    display: table-row;
    white-space: normal;
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
  th, td {
    display: table-cell;
    width: auto;
    text-align: center;
    border: none;
    border-top: $table-style-border;
    border-left: $table-style-border;
    a {
      color: map_get($foundation-palette, primary);
    }
  }
  .monthCell {
    border-right: $table-style-border;
  }
  .weekDay {
    font-weight: $global-weight-bold;
  }
  .tableDescription {
    text-align: left;
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

#monthlyAttendanceTableWrapper, #buildingJournalTableWrapper {
  .loading {
    display: none;
  }
}

.isLoading {
  position: relative;
  &:before {
    position: absolute;
    z-index: 100;
    background-color: rgba($white,.9);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: block !important;
    content: "";
  }
  .loading {
    display: block !important;
    position: absolute;
    z-index: 200;
    top: 50%;
    left: 50%;
    margin-top: -($loading-spinner-large-size / 2);
    margin-left: -($loading-spinner-large-size / 2);
  }
}

.input-group {
  input {
    margin-bottom: 0;
  }
  .input-group-addon {
    margin-bottom: 0;
    padding: 0 15px;
    background: $light-gray;
    align-items: center;
    display: flex;
    border: 1px solid $medium-gray;
    border-left: none;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    i {
      border: 1px solid $medium-gray;
    }
  }
}

.colorpicker-component {
  &.input-group {
    position: relative;
  }
  .input-group-addon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
  }
}

.colorpicker-dropdown-menu {
  @include dropdown-container;
  visibility: visible;
  width: auto;
}

.multipleRowCell {
  td {
    flex-direction: column;
    align-items: flex-start;
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

textarea:disabled {
  color: $dark-gray
}

.datePicker {
  input {
    cursor: pointer;
  }
}

.borderLeft {
  border-left: $content-header-border;
}

.wysiwygError {
  span {
    margin-bottom: 0;
    margin-top: rem-calc(8);
  }
}

.colorWrap {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.colorpick {
  display: flex;
  width: rem-calc(40);
  height: rem-calc(40);
  margin: rem-calc(5 5 0 5);
  background: #f5f5f5;
  border: 1px solid #e2e2e2;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  justify-content: center;
  align-items: center;
  opacity: .6;
  transition: all .3s ease;
  span {
    display: inline-block;
    width: rem-calc(16);
    height: rem-calc(16);
  }
  &:hover {
    opacity: 1;
  }
  @each $color, $value in $customColorsPalete {
    &.#{$color} {
      span {
        background-color: $value;
        @if $color == 'White' {
          border: 1px solid $medium-gray;
        }
      }
    }
  }
}

input[readonly]:not([disabled]) {
  &.fdatepicker {
    cursor: pointer;
    color: $input-color;
    background-color: $input-background;
  }
}

.excelExportWrapper {
  text-align: center;
  @include breakpoint(medium up) {
    text-align: right;
  }
}

.invoiceTemplateVisual {
  position: relative;
  img, label {
    width: 100%;
  }
  label {
    cursor: pointer;
    border: solid 1px $medium-gray;
    margin: 0;
    transition: border-color $transition-time $transition-type;
    @include responsive-size(padding, $base-element-gutter-quarter);
    @include responsive-size(margin-bottom, $base-element-gutter-half);
    &:hover {
      border-color: map_get($foundation-palette, primary);
    }
  }
  input {
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  input:checked + label {
    border-color: map_get($foundation-palette, primary);
  }
}

.tagList {
  font-size: $sonata-tag-list-font-size;
  font-weight: $global-weight-bold;
  margin-top: 0;
  @include responsive-size(margin-bottom, getMapDiv($grid-column-gutter, 2));
  a.tag {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: $global-weight-normal;
  }
}

.middleRightWrapper {
  article {
    @include responsive-size(padding-top, getMapDiv($grid-column-gutter, 2));
  }
}

aside {
  .blockFields {
    height: 100%;
  }
  .blockButtonControl {
    background: $white;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    @include responsive-size(padding-left, getMapDiv($grid-column-gutter, 4));
    @include responsive-size(padding-right, getMapDiv($grid-column-gutter, 4));
    .column {
      @include responsive-size(padding-left, getMapDiv($grid-column-gutter, 4));
      @include responsive-size(padding-right, getMapDiv($grid-column-gutter, 4));
    }
  }
}

.callout {
  @include responsive-size(margin-bottom, getMapDiv($grid-column-gutter, 2));
}

.constructionRevealLink {
  color: $secondary-color;
  text-align: left;
  margin-bottom: 0;
}