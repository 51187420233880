@import '../../settings/components/_top-bar-settings';

/// top bar main styles
.topBar{
    background: $top-bar-bg;
    z-index: z(topbar);
    @include responsive-size(height, $top-bar-heights);
    @include responsive-size(font-size, $top-bar-font-size);
    @if $top-bar-has-shadow{
        box-shadow: $top-bar-shadow;
        border-bottom: none;
    } @else {
        border-bottom: $top-bar-border-style;
    }
    .title{
        @include responsive-size(width, $top-bar-title-widths);
        @include responsive-size(height, $top-bar-heights);
        display: flex;
        align-items: center;
        line-height: 1;
        background: $title-bg;

        white-space: nowrap;
        overflow: hidden;
        position: relative;
        @if $top-bar-has-logo == true {
            .logo {
                display: flex;
                align-items: center;
                svg {
                    @include responsive-size(width, $top-bar-logo-size);
                    fill: $top-bar-logo-svg-color;
                    height: auto;
                    max-height: 100%;
                }
            }
        } @else {
            strong {
                color: $title-primary-color;
            }
            small {
                color: $title-secondary-color;
            }
        }
        @include isSet($title-bg-shadow) {
            &:after{
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                z-index: 2;
                display: block;
                content: "";
                box-shadow: $title-bg-shadow;
                pointer-events: none;
            }
        }
    }
    .controls{
        display: flex;
        flex: 1 1 auto;
        @include responsive-size(padding, $top-bar-controls-padding);
        > .row{
            width: 100%;
        }
        svg{
            fill: $top-bar-icons-color;
            @if $top-bar-has-icon-size {
                @include responsive-size(height, $top-bar-icons-size);
                @include responsive-size(width, $top-bar-icons-size);
            }
            &.iconArrow {
                margin: $top-bar-icon-arrow-margin;
                @include responsive-size(width, $top-bar-icon-arrow-size);
                @include responsive-size(height, $top-bar-icon-arrow-size);
                &.down {
                    transform: rotate(90deg);
                }
            }
        }
    }
    .button-group {
        margin-bottom: 0;
    }
    @if $dropdown-in-top-bar{
        .dropdown{
            &.menu{
                > li{
                    > a:not(.button){
                        color: $dropdown-in-top-bar-color;
                        @include responsive-size(padding, $dropdown-in-top-bar-link-padding);
                        @include responsive-size(line-height, $top-bar-heights);
                        @include responsive-size(height, $top-bar-heights);
                    }
                }
                .userAvatar {
                    @include responsive-size(margin-right, $top-bar-avatar-margin-right);
                    img {
                        display: block;
                        @include responsive-size(height, $top-bar-avatar-heights);
                        border: $top-bar-avatar-border;
                        @if $top-bar-avatar-circle {
                            border-radius: 50%;
                        }
                    }
                }
                small{
                    color: $dropdown-in-top-bar-small-color;
                    line-height: $dropdown-in-top-bar-small-line-height;
                    text-transform: $dropdown-in-top-bar-small-text-transform;
                }
                strong{
                    color: $dropdown-in-top-bar-primary-color;
                    line-height: $dropdown-in-top-bar-primary-line-height;
                    text-transform: $dropdown-in-top-bar-primary-text-transform;
                }
            }
        }
    }
}
