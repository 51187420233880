/* global -  nastavení komponent nutné pro základní rozvržení,
*  tyto hodnoty je nutné mít nastavené i přes to, že se s nimi nepracuje v app-settings.
*/
// ---------

$base-font-sizes: (
    null: rem-calc(12),
    large: rem-calc(13),
    xlarge: rem-calc(14),
    xxlarge: rem-calc(15),
);
$base-form-elements-heights: (
    null: rem-calc(35px),
    large: rem-calc(37px),
    xlarge: rem-calc(40px),
    xxlarge: rem-calc(42px),
);
$base-shadow-color: rgba($black, .2);
$base-element-gutter-half: getMapDiv($grid-column-gutter, 2);
$base-element-gutter-third: getMapDiv($grid-column-gutter, 3);
$base-element-gutter-quarter: getMapDiv($grid-column-gutter, 4);
$base-element-gutter-bottom: $base-element-gutter-half;
$base-element-gutter-top: $base-element-gutter-half;

$zindex-values: (
    topbar: 20,
    contentheader: 10,
    nav: 11,
    aside: 9,
    content: 7
);
$main-content-wrapper-padding: (
        null: rem-calc(15),
        medium: rem-calc(20),
);
// aside - used in layout
// ---------
$aside-sizes: (
    null: 100%,
    small: rem-calc(180),
    large: rem-calc(200),
    xlarge: rem-calc(220),
    xxlarge: rem-calc(240),
);
// nav - used in layout
// ---------
$nav-sizes:(
    null: 100%,
    small: rem-calc(180),
    large: rem-calc(200),
    xlarge: rem-calc(220),
    xxlarge: rem-calc(240),
);

$has-custom-responsive-classes: true;