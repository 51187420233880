.tableScroll {
    [name="emaillog-headerfrom"] {
        @include td-col(6);
    }
    [name="emaillog-headerto"] {
        @include td-col(6);
    }
    [name="emaillog-messagesubject"] {
        @include td-col(7);
    }
    [name="emaillog-datesend"] {
        @include td-col(5);
    }

    @include breakpoint(medium up) {
        [name="emaillog-headerfrom"] {
            @include td-col(5);
        }
        [name="emaillog-headerto"] {
            @include td-col(5);
        }
        [name="emaillog-messagesubject"] {
            @include td-col(6);
        }
        [name="emaillog-datesend"] {
            @include td-col(4);
        }
    }
}