$dashboard-gutter: getMapDiv($grid-column-gutter, 2);

.divisionDashboard {
    @include responsive-size(padding-left, getMapDiv($dashboard-gutter, 2));
    @include responsive-size(padding-right, getMapDiv($dashboard-gutter, 2));
    .ui-state-highlight{
        box-sizing: border-box;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0;
        position: relative;
        &:after, &:before{
            position: absolute;
            content: "";
            display: block;
            top: 0;
            right: rem-calc(10);
            left: rem-calc(10);
            bottom: rem-calc(30);
        }
        &:after{
            background-color: $white;
            animation: scaleBackground .5s ease-out;
            animation-iteration-count: 1;
            //border: 2px dashed $elementStyleColor;
            z-index: 10;
        }
        &:before{
            background-color: rgba($elementStyleColor, 0.8);
            box-shadow: 0 2px 5px 0 rgba(map_get($customColorsPalete, Brown), .15);
            animation: pulsate 2s ease-out;
            animation-iteration-count: infinite;
            opacity: 0.0;

        }
        @keyframes pulsate {
            0% {box-shadow: 0 0 5px 0 rgba(map_get($customColorsPalete, Brown), .15); opacity: 0.0;}
            50% {opacity: 1.0;}
            100% { box-shadow: 0 0 20px 0 rgba(map_get($customColorsPalete, Brown), .15); opacity: 0.0;}
        }
        @keyframes scaleBackground {
            0% {transform: scale(0.99); opacity: 0.6;}
            100% { transform: scale(1); opacity: 1;}
        }
    }
    .callout {
        &.info {
            background-color: mix(map_get($customColorsPalete, Blue), $white, 20);
            a {
                color: map_get($customColorsPalete, Blue);
            }
        }
        &.empty {
            padding: rem-calc(15);
        }
    }
    &.preloaderWrapper{
        &:after{
            background-color: rgba(desaturate(lighten($secondary-color, 40%), 5%), .6);
        }
    }
    .row {
        @include flex-grid-row(null, $grid-row-width, $grid-column-count, $dashboard-gutter);
    }
    .column, .columns {
        &.xsmall-24 {
            @include flex-grid-column($grid-column-count, $dashboard-gutter);
        }
        @include breakpoint(medium up) {
            &.medium-12 {
                @include flex-grid-column(12, $dashboard-gutter);
            }
        }
    }
}
.dashboardWidget{
    position: relative;
    background: $white;
    box-shadow: 0 2px 5px 0 rgba(map_get($customColorsPalete, Brown), .15);
    @include responsive-size(margin-bottom, $dashboard-gutter);
    &:after, &:before {
        content: '';
        position: absolute;
        display: none;
    }
    &:after {
        background: mix($white, $black, 20);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
    }
    &:before {
        left: 50%;
        top: 50%;
        margin-top: -($loading-spinner-large-size / 2);
        margin-left: -($loading-spinner-large-size / 2);
        background-color: transparent;
        border: rem-calc(2) solid $primary-color;
        border-top-color: rgba($black,.1);;
        border-right-color: rgba($black,.1);;
        width: $loading-spinner-large-size;
        height: $loading-spinner-large-size;
        border-radius: 50%;
        z-index: 101;
        animation: spinner-animation .65s infinite linear;
    }
    header{
        border-bottom: 1px solid $elementStyleColor;
        background: $table-head-bg;
        padding: rem-calc(5 15);
        h2{
            margin: 0;
            color: $secondary-color;
            font-weight: bold;
            @include responsive-size('font-size', $d-widget-header-font-size);
        }
        .controls{
            opacity: 0;
            transition: opacity .3s ease;
        }
        &:hover{
            .controls{
                opacity: 1;
            }
        }
    }
    h2 {
        @extend .breakWord;
    }
    footer{
        padding: rem-calc(0 15 0 15);
    }
    .content{
        padding: rem-calc(15 15 0 15);
        display: block;
        @include clearfix();
    }
    .listTable{
        border: none;
    }
    &.isEdited{
        background: $table-head-bg;
        input[type="text"]{
            &:focus{
                background-color: $white;
            }
        }
        header{
            .controls{
                visibility: hidden;
            }
        }
    }
    &.preloaderWrapper{
        &:after{
            background-color: rgba($white, .8);
        }
    }
    [name="widget-action"] {
        @include td-col(3);
    }
    .tableScrollHalf {
        @include generateSrollTable(true, '', $table-scroll-grid-prefix, $table-scroll-limit-width, xxlarge);
    }
    &.preloaderIsVisible {
        &:after, &:before {
            display: block;
        }
    }
    @include breakpoint(large up){
        [name="widget-action"] {
            @include td-col(2);
        }
    }
    @include breakpoint(xlarge up){
        [name="widget-action"] {
            @include td-col(2);
        }
    }
    @include breakpoint(xxlarge up){
        [name="widget-action"] {
            @include td-col(4);
        }
    }
}
.ui-sortable-helper{
    &:hover{
        header{
            .controls{
                opacity: 1;
            }
        }
    }
    header{
        .controls{
            opacity: 1;
        }
    }
}
.dahsboardDropdown {
    z-index: 10;
    width: rem-calc(200);
    right: 0;
    padding: rem-calc(5);
    visibility: hidden;
    border: rem-calc(1) solid $dropdown-menu-border-color;
    border-radius: 0;
    background-color: $dropdown-menu-background-color;
    font-size: 1rem;
    transform: translateY(rem-calc(-18));
    transition: transform .3s ease, opacity .3s ease;
    &:before {
        border: inset 6px;
        content: "";
        display: block;
        height: 0;
        width: 0;
        border-color: transparent transparent $white transparent;
        border-bottom-style: solid;
        position: absolute;
        top: rem-calc(-12);
        left: 10px;
        z-index: 89;
    }
    &:after {
        border: inset 7px;
        content: "";
        display: block;
        height: 0;
        width: 0;
        border-color: transparent transparent $dropdown-menu-border-color transparent;
        border-bottom-style: solid;
        position: absolute;
        top: rem-calc(-14);
        left: 9px;
        z-index: 88;
    }
    li {
        background-color: $white;
        transition: background-color $transition-time $transition-type;
        &:hover {
            background-color: mix($white, $black, 97);
        }
        a {
            padding: rem-calc(8);
            text-decoration: none;
            color: $dropdown-menu-anchor-color;
        }
    }
    &.is-open {
        transform: translateY(0);
        display: block;
        opacity: 1;
        &:before {
            left: rem-calc(177);
        }
        &:after {
            left: rem-calc(176);
        }
    }
}
@include spinner-keyframes;