@import '../settings/components/login-screen-settings';

.loginScreen {
    display: flex;
    flex: 0 0 100%;
    flex-direction: row;
    flex-basis: 100%;
    align-items: center;
    > .row{
        max-width: 100%;
        flex-basis: 100%;
    }
    .loginContent {
        background: $login-bg;
        @if $login-has-shadow {
            box-shadow: 0px 2px 4px -1px rgba(10,10,10,0.3);
        }
    }
    .loginLogoBlock {
        border-bottom: 2px solid $login-logoBlock-border-color;
        @include responsive-size(margin-bottom, $base-element-gutter-half);
        @if $login-has-logo {
            .logo {
                @include responsive-size(padding-bottom, $grid-column-gutter);
                @include responsive-size(padding-top, $grid-column-gutter);
                text-align: center;
                svg {
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    width: $login-logo-width;
                    height: $login-logo-height;
                }
            }
        }
    }
    .loginFormBlock {
        @include responsive-size(margin-bottom, $base-element-gutter-half);
    }
}
