@import '../settings/hamburgers-settings';

.hamburger-box{
    vertical-align: middle;
}
.hamburger--arrow{
    &.is-active{
        .hamburger-inner {
            &:before, &:after {
                width: 15px;
            }
            &:before {
                top: -3px;
            }
            &:after{
                bottom: -3px;
            }
        }
    }
}