/// top bar widths, padding/margins and font-sizes
$top-bar-heights: (
    null: rem-calc(45),
    medium: rem-calc(50),
    xlarge: rem-calc(55),
) !default;

$top-bar-title-widths: (
    null: auto,
    small: rem-calc(180),
    large: rem-calc(200),
    xlarge: rem-calc(220),
    xxlarge: rem-calc(240),
) !default;
$top-bar-controls-padding: (
    null: rem-calc(0),
    medium: rem-calc(0),
) !default;

$top-bar-has-icon-size: true !default;
$top-bar-icons-size: (
    null: rem-calc(20),
    medium: rem-calc(25),
) !default;

$top-bar-font-size: (
    null: rem-calc(12),
    medium: rem-calc(14)
) !default;

$top-bar-font-size: $base-font-sizes !default;
$top-bar-bg: $white !default;
$top-bar-border-style: 1px solid rgba($black, .1) !default;
$top-bar-has-shadow: true !default;;
$top-bar-shadow: 0 0 5px $base-shadow-color !default;
$top-bar-has-logo: true !default;
$top-bar-logo-svg-color: $white !default;
$top-bar-logo-size: (
    null: 100%,
    large: 65%
) !default;
$title-primary-color: map-get($foundation-palette, primary) !default;
$title-secondary-color: $dark-gray !default;
$title-bg: none !default;
$title-bg-shadow: none !default;
$top-bar-icons-color: map-get($foundation-palette, primary) !default;

$dropdown-in-top-bar: true;
$dropdown-in-top-bar-link-padding: (
    null: rem-calc(0 10 0 10),
    medium: rem-calc(0 16 0 16),
) !default;
$dropdown-in-top-bar-color: $body-font-color !default;
$dropdown-in-top-bar-primary-color: map-get($foundation-palette, primary) !default;
$dropdown-in-top-bar-small-color: $dark-gray !default;
$dropdown-in-top-bar-primary-text-transform: uppercase !default;
$dropdown-in-top-bar-small-text-transform: $dropdown-in-top-bar-primary-text-transform !default;
$dropdown-in-top-bar-small-line-height: 1 !default;
$dropdown-in-top-bar-primary-line-height: 1.5 !default;

$top-bar-avatar-border: 2px solid $white !default;
$top-bar-avatar-circle: true !default;
$top-bar-avatar-heights: getMapMulti($top-bar-heights, .65) !default;
$top-bar-avatar-margin-right: (
    null: 10px,
    large: 15px
) !default;

$top-bar-has-search-field: true !default;
$top-bar-search-field-class: "fieldSearch" !default;
$top-bar-search-field-margin: 0 !default;
$top-bar-icon-search-color: mix($secondary-color, $white, 45%) !default;
$top-bar-icon-search-size: (
    null: 25px,
    xlarge: 25px
) !default;
$top-bar-icon-arrow-margin: rem-calc(0 0 0 10) !default;
$top-bar-icon-arrow-size: (
    null: 16px,
    medium: 18px
) !default;
