.tableScroll {
    [name="jobinterview-date"] {
        @include td-col(5);
    }
    [name="jobinterview-place"] {
        @include td-col(6);
    }
    [name="jobinterview-totalcountofparticipants"] {
        @include td-col(5);
    }
    [name="jobinterview-_action"] {
        @include td-col(5);
    }

    @include breakpoint(medium up) {
        [name="jobinterview-date"] {
            @include td-col(4);
        }
        [name="jobinterview-place"] {
            @include td-col(5);
        }
        [name="jobinterview-totalcountofparticipants"] {
            @include td-col(4);
        }
        [name="jobinterview-_action"] {
            @include td-col(4);
        }
    }

    @include breakpoint(large up) {
        [name="jobinterview-place"] {
            @include td-col(7);
        }
    }

    @include breakpoint(xxlarge up) {
        [name="jobinterview-place"] {
            @include td-col(6);
        }
        [name="jobinterview-_action"] {
            @include td-col(3);
        }
    }
}