.tableScroll {
    [name="joborderattendance-day"] {
        @include td-col(3);
    }
    [name="joborderattendance-joborder"] {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        a {
            padding-right: $global-padding;
        }
        @include td-col(5);
    }
    [name="joborderattendance-presentpeoplecount"], [name="joborderattendance-peopleonotherbuildingcount"], [name="joborderattendance-peopleonvacationcount"], [name="joborderattendance-sickpeoplecount"], [name="joborderattendance-peoplecount"] {
        @include td-col(3);
    }
    [name="joborderattendance-lastmodification"] {
        @include td-col(5);
    }
    [name="joborderattendance-_action"] {
        @include td-col(3);
    }

    @include breakpoint(small up) {
        [name="joborderattendance-joborder"] {
            @include td-col(4);
        }
    }

    @include breakpoint(medium up) {
        [name="joborderattendance-day"] {
            @include td-col(2);
        }
        [name="joborderattendance-joborder"] {
            @include td-col(3);
        }
        [name="joborderattendance-presentpeoplecount"], [name="joborderattendance-peopleonotherbuildingcount"], [name="joborderattendance-peopleonvacationcount"], [name="joborderattendance-sickpeoplecount"], [name="joborderattendance-peoplecount"] {
            @include td-col(2);
        }
        [name="joborderattendance-lastmodification"] {
            @include td-col(3);
        }
        [name="joborderattendance-_action"] {
            @include td-col(2);
        }
    }

    @include breakpoint(xlarge up) {
        [name="joborderattendance-joborder"] {
            @include td-col(2);
        }
        [name="joborderattendance-_action"] {
            @include td-col(2);
        }
    }

    @include breakpoint(xxlarge up) {
        [name="joborderattendance-joborder"] {
            @include td-col(3);
        }
    }
}