@import '../settings/components/sonata-table-settings';

.button-icon{
    display: inline-block;
    vertical-align: top;
    @include responsive-size(width, $sonata-button-icon-size);
    @include responsive-size(height, $sonata-button-icon-size);
    fill: $sonata-button-icon-color;
    svg{
        width: 100%;
        height: 100%;
    }
}
.tagList{
    @include responsive-size(margin, $sonata-tag-list-margin);
    a.tag{
        background: $sonata-tag-list-tag-bg;
        border: $sonata-tag-list-tag-border;
        display: block;
        position: relative;
        color: $sonata-tag-list-tag-color;
        @include responsive-size(padding, $sonata-tag-list-tag-padding);
        @include responsive-size(margin, $sonata-tag-list-tag-margin);
        @include responsive-size(font-size, $sonata-tag-list-font-size);
        svg{
            position: absolute;
            width: $sonata-tag-list-tag-icon-size;
            height: $sonata-tag-list-tag-icon-size;
            fill: $sonata-tag-list-tag-icon-color;
            top: 50%;
            margin-top: -($sonata-tag-list-tag-icon-size/2);
            @include responsive-size(right, $sonata-tag-list-tag-close-right)
        }
        &:hover, &:focus{
            background: $sonata-tag-list-tag-hover-bg;
            border-color: $sonata-tag-list-tag-hover-color;
        }
    }
}

table {
    width: auto;
    th[class*="sonata-ba-list-field-header"] {
        padding: 0;
        position: relative;
        a, > span {
            @include responsive-size(padding-top, $sonata-table-head-padding-top);
            @include responsive-size(padding-bottom, $sonata-table-head-padding-bottom);
            @include responsive-size(padding-left, $sonata-table-head-padding-left);
            padding-right: $sonata-table-icon-svg-width + rem-calc(7px);
            color: $sonata-thead-color;
            position: relative;
            width: 100%;
            height: 100%;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: $sonata-table-head-weight;
        }
        svg {
            fill: $sonata-table-icon-svg-color;
            width: $sonata-table-icon-svg-width;
            height: $sonata-table-icon-svg-height;
            position: absolute;
            right: rem-calc(5);
            top: 50%;
            margin-top: #{rem-calc(ceil(-$table-icon-svg-height/2))};
        }
    }
    th[class*="sonata-ba-list-field-order-active"] {
        background: $sonata-table-head-bg-active;
        a {
            color: $sonata-table-head-color-active;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            height: $sonata-table-head-active-line-height;
            bottom: $sonata-table-head-border-bottom-offset;
            left: $sonata-table-head-border-left-offset;
            right: $sonata-table-head-border-right-offset;
            background: $sonata-table-head-border-bottom-active;
        }
    }
    th[class*="sonata-ba-list-field-header-batch"], td[class*="sonata-ba-list-field-batch"] {
        text-align: center;
        justify-content: center;
        input[type="checkbox"] {
            vertical-align: middle;
            margin: 0;
        }
    }
    td[class*="sonata-ba-list-field-actions"] {
        justify-content: flex-end;
        padding: 0;
    }
}