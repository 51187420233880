$aside-sizes: (
    null: rem-calc(200),
    medium: rem-calc(220),
    large: rem-calc(260),
) !default;
$aside-padding: (
    null: rem-calc(0 getBaseGutter(xsmall) 0 getBaseGutter(xsmall)),
    medium: rem-calc(0 getBaseGutter(medium) 0 getBaseGutter(medium)),
) !default;

$aside-switchWrapper-padding: (
    null: rem-calc(getBaseGutter(xsmall) 0),
    medium: rem-calc(getBaseGutter(medium)0),
) !default;
$aside-bottom-switch-visibility: visible !default;
$aside-font-size: $base-font-sizes !default;
$aside-bg: $white !default;
$aside-border-style: 1px solid rgba($black, .1) !default;
$aside-has-shadow: true !default;
$aside-shadow: 0 0 5px rgba($black, .2) !default;

$aside-bg: $white !default;
$aside-has-form-elements-style: true !default;
$aside-input-bg: $white !default;
$aside-input-shadow: inset 0 1px 2px $base-shadow-color !default;
$aside-input-border: 1px solid #e2e2e2 !default;
$aside-input-label: $black !default;
$aside-switchWrapper-bg: $white !default;
$aside-switch-labels-color: $black !default;
$aside-switch-bg: $light-gray !default;
$aside-switch-paddle-bg: $white !default;
$aside-switch-paddle-bg-active: #466b9c !default;
$aside-switch-vertical-padding: 5 !default;
$aside-switch-labels-text-transform: uppercase !default;
$aside-switch-labels-padding: rem-calc(0 5) !default;
$aside-switch-labels-font-sizes: (null: rem-calc(10)) !default;