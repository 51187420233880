/// content header widths, padding/margins and font-sizes
$content-header-padding: (
    null: rem-calc(getBaseGutter(xsmall)  getBaseGutter(xsmall)  0 getBaseGutter(xsmall) ),
    medium: rem-calc(getBaseGutter(medium)  getBaseGutter(medium)  0 getBaseGutter(medium)),
) !default;

$content-header-h1-margin: (
    null: rem-calc(0 0 getBaseGutter(xsmall)  0),
    medium: rem-calc(0 0 getBaseGutter(medium)  0),
) !default;

$content-header-bg: $white !default;
$content-header-border: 1px solid $light-gray !default;
$content-header-has-shadow: true !default;
$content-header-shadow: 0 0 5px rgba($black, .2) !default;
$content-header-color: mix($primary-color, $white, 90%) !default;
$content-header-h1-color: map-get($foundation-palette, secondary) !default;
$content-header-h1-weight: bold !default;