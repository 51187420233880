@import '../settings/icons-settings';

.icon{
    &, svg{
        fill: $icon-color;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: rem-calc(16);
        @include responsive-size(width, $icon-width);
        @include responsive-size(height, $icon-height);
    }
}
.iconShow{
    .toggleIcon &{
        display: none;
    }
}
.iconHide{
    &, .icon &{
        display: none;
    }
    .toggleIcon &{
        display: inline-block;
    }
}

// TODO: Vytvorit mixinu pre zadavanie pomeru ikonam