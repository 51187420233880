@import '../../settings/components/flex-grid-settings';
// Remove after foundation fix issue
.row .row{
    max-width: none;
}

.column {
    // domena priorit konfliktnich vlasnosti
    @if($has-custom-flex-grid-responsive-classes) {
        $closest-size: null;
        @each $size, $value in $breakpoints {
            $temp-breakpoint: ($size : $value);
            @if map-has-key($base-element-gutter-half, $size) {
                $closest-size: rem-calc(map-get($base-element-gutter-half, $size));
            }
            @include responsive-generator-class-content(collapse, $temp-breakpoint) {
                padding-left: 0;
                padding-right: 0;
            }
            @include responsive-generator-class-content(uncollapse, $temp-breakpoint) {
                padding-left: $closest-size;
                padding-right: $closest-size;
            }
            @include responsive-generator-class-content(uncollapse-left, $temp-breakpoint) {
                padding-left: $closest-size;
            }
            @include responsive-generator-class-content(uncollapse-right, $temp-breakpoint) {
                padding-right: $closest-size;
            }
            @include responsive-generator-class-content(collapse-left, $temp-breakpoint) {
                padding-left: 0;
            }
            @include responsive-generator-class-content(collapse-right, $temp-breakpoint) {
                padding-right: 0;
            }
            @include responsive-generator-class-content(align-right, $temp-breakpoint) {
                justify-content: flex-end
            }
            @include responsive-generator-class-content(align-center, $temp-breakpoint) {
                justify-content: center
            }
            @include responsive-generator-class-content(shrink, $temp-breakpoint) {
                flex-grow: 0;
                flex-shrink: 0;
            }
            /// Reset triedy shrink
            @include responsive-generator-class-content(unshrink, $temp-breakpoint) {
                flex-grow: 1;
                flex-shrink: 0;
            }
        }
    }
}
