.tableScroll {
    [name="tool-name"] {
        @include td-col(5);
    }
    [name="tool-internalnumber"] {
        @include td-col(5);
    }
    [name="tool-category.title"] {
        @include td-col(5);
    }
    [name="tool-location"] {
        @include td-col(4);
    }
    [name="tool-warrantyexpirationdate"] {
        @include td-col(4);
    }
    [name="tool-patexpirationdate"] {
        @include td-col(4);
    }
    [name="tool-state"] {
        @include td-col(3);
    }
    [name="tool-_action"] {
        @include td-col(3);
    }

    [name="toolwarehouse-name"] {
        @include td-col(5);
    }
    [name="toolwarehouse-location"] {
        @include td-col(4);
    }
    [name="toolwarehouse-countoftools"] {
        @include td-col(4);
    }
    [name="toolwarehouse-countofavailabletools"] {
        @include td-col(4);
    }
    [name="toolwarehouse-countofborrowedtools"] {
        @include td-col(4);
    }
    [name="toolwarehouse-_action"] {
        @include td-col(4);
    }

    @include breakpoint(medium up) {
        [name="tool-name"] {
            @include td-col(4);
        }
        [name="tool-internalnumber"] {
            @include td-col(4);
        }
        [name="tool-category.title"] {
            @include td-col(4);
        }
        [name="tool-location"] {
            @include td-col(3);
        }
        [name="tool-warrantyexpirationdate"] {
            @include td-col(3);
        }
        [name="tool-patexpirationdate"] {
            @include td-col(3);
        }
        [name="tool-state"] {
            @include td-col(2);
        }
        [name="tool-_action"] {
            @include td-col(2);
        }

        [name="toolwarehouse-name"] {
            @include td-col(4);
        }
        [name="toolwarehouse-location"] {
            @include td-col(3);
        }
        [name="toolwarehouse-countoftools"] {
            @include td-col(3);
        }
        [name="toolwarehouse-countofavailabletools"] {
            @include td-col(3);
        }
        [name="toolwarehouse-countofborrowedtools"] {
            @include td-col(3);
        }
        [name="toolwarehouse-_action"] {
            @include td-col(3);
        }
    }

    @include breakpoint(xxlarge up) {
        [name="tool-name"] {
            @include td-col(3);
        }
        [name="tool-internalnumber"] {
            @include td-col(3);
        }
        [name="tool-category.title"] {
            @include td-col(4);
        }
        [name="tool-location"] {
            @include td-col(3);
        }
        [name="tool-warrantyexpirationdate"] {
            @include td-col(2);
        }
        [name="tool-patexpirationdate"] {
            @include td-col(2);
        }
        [name="tool-state"] {
            @include td-col(2);
        }
        [name="tool-_action"] {
            @include td-col(2);
        }
    }
}