$table-row-hover-bg: mix($white, $black, 92%) !default;
$table-font-size: $base-font-sizes !default;
$table-even-row-bg: $white !default;
$table-odd-row-bg: $light-gray !default;
$table-border-color: $medium-gray !default;
$table-style-border: 1px solid $table-border-color !default;
$table-border: false !default;
$table-style-shadow: none !default;
$table-control-icon-gutter: rem-calc(5) !default;
$table-main-content-wrapper-padding: $main-content-wrapper-padding !default;
$table-head-has-border-vertical: true !default;

$table-head-bg: $white !default;
$table-head-color: $body-font-color !default;
$table-head-font-weight: bold !default;
$table-body-color: $black !default;
$table-foot-bg: $white !default;
$table-foot-color: $black !default;
$table-grid-cols: 10 !default;
$table-breakpoints: $breakpoints !default;
$table-icon-svg-color: $secondary-color !default;
$table-icon-svg-width: 18px !default;
$table-icon-svg-height: 18px !default;
$table-button-icon-ratio: 0.6 !default;
$table-button-sizes:(
        null: 35px,
        medium : 42px,
) !default;

$pagination-item-bg: $white !default;
$pagination-item-hover-bg: $primary-color !default;
$pagination-item-color: $primary-color !default;
$pagination-item-hover-color: $white !default;