$form-field-font-sizes: $base-font-sizes !default;
$form-textarea-font-sizes: $form-field-font-sizes !default;
$button-group-font-sizes: $base-font-sizes !default;

$label-font-sizes: $form-field-font-sizes !default;
$label-required-symbol: null !default;
$label-required-symbol-color: $body-font-color !default;
$label-required-symbol-size: $base-font-sizes !default;
$label-required-symbol-margin-left: rem-calc(3) !default;
$button-font-weight: 500 !default;
$legend-color: $body-font-color !default;
$legend-background: transparent !default;
$legend-text-transform: uppercase !default;
$legend-font-weight: 700 !default;
$legend-font-sizes: (
    null: rem-calc(16),
    medium: rem-calc(18),
    large: rem-calc(20)
) !default;
$legend-margin-bottom: $base-element-gutter-bottom !default;
$legend-line-height: $header-lineheight !default;
$input-color-disabled: $dark-gray !default;
$input-placeholder-color-disabled: $dark-gray !default;
$select-background-disabled: $input-background-disabled !default;
