@import '../../settings/components/nav-settings';

/// navigation main styles
nav {
    background-color: $nav-level0-background;
    @include responsive-size(width, $nav-sizes);
}
.side-nav{
    margin: 0;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative;
    z-index: 1;
    border-right: $nav-level0-border-right;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    box-shadow: $nav-level0-shadow;
    @if $nav-level0-shadow-style == inset {
        box-shadow: none;
        &:after{
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            z-index: 10;
            position: absolute;
            right: 0;
            top: 0;
            box-shadow: $nav-level0-shadow inset;
            pointer-events: none;
        }
    }
    li {
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        span {
            flex: 1 1 1px;
        }
    }
    & > li {
        border-bottom: $nav-level0-item-border;
        @include responsive-size(padding-left, $nav-level0-item-paddings-left);
        @include responsive-size(font-size, $nav-level0-font-size);
        .icon {
            @include responsive-size(width, $nav-level0-icon-sizes);
            @include responsive-size(height, $nav-level0-icon-sizes);
            margin: rem-calc(0 5 0 0);
            flex: 0 0 auto;
            &, svg {
                fill: $nav-level0-icon-color;
            }
        }
        .arrow {
            @include responsive-size(width, $nav-level0-arrow-sizes);
            @include responsive-size(height, $nav-level0-arrow-sizes);
            fill: $nav-level0-arrow-color;
            margin: rem-calc(0 0 0 5);
            flex: 0 0 auto;
        }
        a:not(.button), strong{
            @include responsive-size(padding, $nav-level0-link-padding);
            position: relative;
            z-index: 0;
            width: 100%;
            text-decoration: none;
            display: flex;
            align-items: center;
        }
        & > a:not(.button){
            color: $nav-level0-link-color;
            font-weight: $nav-level0-font-weight;
            &:hover, &:focus {
                background: $nav-level0-current-bg-hover;
            }
        }
        strong{
            display: block;
            color: darken($secondary-color, 20%);
            .icon, .arrow{
                fill: darken($secondary-color, 20%) !important;
            }
        }
        &.current{
            & > a:not(.button), strong {
                background: $nav-level0-current-bg;
                font-weight: $nav-level0-current-font-weight;
                color: $nav-level0-current-color;
            }
        }
        ul > li {
            @include responsive-size(padding-left, $nav-level1-item-paddings-left);
            &:first-of-type {
                border-top: $nav-level1-item-border;
            }
            &:not(:last-of-type) {
                border-bottom: $nav-level1-item-border;
            }
            @include responsive-size(font-size, $nav-level1-font-size);
            .arrow {
                margin: rem-calc(0 5 0 0);
                fill: $nav-level1-icon-color;
            }
            a:not(.button), strong {
                @include responsive-size(padding, $nav-level1-link-padding);
                color: $nav-level1-link-color;
                font-weight: $nav-level1-link-font-weight;
            }
            a:not(.button) {
                font-weight: $nav-level1-font-weight;
                &:hover, &:focus {
                    background: $nav-level1-current-bg-hover;
                }
            }
            &.current {
                & > a:not(.button), strong {
                    font-weight: $nav-level1-current-font-weight;
                    color: $nav-level1-current-color;
                    &:hover, &:focus {
                        background: $nav-level1-current-bg-hover;
                    }
                }
            }
            ul > li {
                border-bottom: $nav-level2-item-border;
                @include responsive-size(padding-left, $nav-level2-item-paddings-left);
                @include isSet($nav-level2-font-size) {
                    @include responsive-size(font-size, $nav-level2-font-size);
                }
                a:not(.button), strong {
                    @include responsive-size(padding, $nav-level2-link-padding);
                    color: $nav-level2-link-color;
                    font-weight: normal;
                }
                a:not(.button) {
                    font-weight: $nav-level2-font-weight;
                    &:hover, &:focus {
                        background: $nav-level2-current-bg-hover;
                    }
                }
                &.current {
                    & > a:not(.button), strong {
                        font-weight: normal;
                        color: $nav-level2-current-color;
                        &:hover, &:focus {
                            background: $nav-level2-current-bg-hover;
                        }
                    }
                }
            }
        }
    }
    ul {
        margin-left: 0;
        background: $nav-level0-current-bg;
    }
}
