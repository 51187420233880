.tableScroll {
    [name="joborder-id"] {
        @include td-col(3);
    }
    [name="joborder-title"] {
        @include td-col(4);
    }
    [name="joborder-realizationstartdate"] {
        @include td-col(4);
    }
    [name="joborder-realizationenddate"] {
        @include td-col(4);
    }
    [name="joborder-state"] {
        @include td-col(3);
    }
    [name="joborder-_action"] {
        @include td-col(6);
    }

    @include breakpoint(small up) {
        [name="joborder-id"] {
            @include td-col(2);
        }
    }

    @include breakpoint(medium up) {
        [name="joborder-title"] {
            @include td-col(3);
        }
        [name="joborder-realizationstartdate"] {
            @include td-col(3);
        }
        [name="joborder-realizationenddate"] {
            @include td-col(3);
        }
        [name="joborder-state"] {
            @include td-col(2);
        }
        [name="joborder-_action"] {
            @include td-col(5);
        }
    }

    @include breakpoint(xlarge up) {
        [name="joborder-id"] {
            @include td-col(1);
        }
        [name="joborder-realizationstartdate"] {
            @include td-col(2);
        }
        [name="joborder-realizationenddate"] {
            @include td-col(2);
        }
        [name="joborder-state"] {
            @include td-col(2);
        }
        [name="joborder-_action"] {
            @include td-col(4);
        }
    }
}