@import '../settings/components/list-settings';

.mainContentWrapper {
    width: 100%;
    @include responsive-size(padding, $main-content-wrapper-padding);
}

.word-break {
    word-break: break-all;
}
.listTable {
    border-collapse: separate;
    width: 100%;
    @include responsive-size(margin-bottom, $base-element-gutter-bottom);
    @include isSet($table-style-shadow) {
        box-shadow: $table-style-shadow;
    }
    th {
        position: relative;
        text-align: left;
        background: $table-head-bg;
        color: $table-head-color;
        font-weight: $table-head-font-weight;
        border-top: 1px solid $table-border-color;
        border-bottom: 1px solid $table-border-color;
        @if $table-head-has-border-vertical {
            border-right: 1px solid $table-border-color;
        } @else {
            &:last-of-type {
                border-right: 1px solid $table-border-color;
            }
        }
        a {
            display: block;
            color: $table-head-color;
            position: relative;
            width: 100%;
            height: 100%;
            text-decoration: none;
        }
        svg {
            fill: $table-icon-svg-color;
            width: rem-calc($table-icon-svg-width);
            height: rem-calc($table-icon-svg-height);
            position: absolute;
            right: rem-calc(5);
            top: 50%;
            margin-top: #{rem-calc(ceil(-$table-icon-svg-height/2))};
        }
    }
    th:first-of-type {
        border-left: 1px solid $table-border-color;
    }
    td.field-actions {
        padding: 0;
    }
    @if $table-border == true  {
        td {
            border-bottom: 1px solid $table-border-color;
            border-right: 1px solid $table-border-color;
        }
        td:first-of-type {
            border-left: 1px solid $table-border-color;
        }
    }
    tbody {
        color: $table-body-color;
        tr:nth-child(2n) {
            background: $table-even-row-bg;
        }
        tr:nth-child(2n +1) {
            background: $table-odd-row-bg;
        }
    }
    td, th, .label {
        @include responsive-size(font-size, $table-font-size);
    }
    tr td:last-child {
        border-right: $table-style-border;
    }
    tr td:first-child {
        border-left: $table-style-border;
    }
    tr:last-child td {
        border-bottom: $table-style-border;
    }
    tfoot {
        background: $table-foot-bg;
        color: $table-foot-color;
        border: $table-style-border;
    }
    .controls {
        padding: 0
    }
    .button {
        margin-bottom: 0;
    }
    .controlsWrapper {
        white-space: nowrap;
        text-align: right;
        .button-icon {
            margin: 0 $table-control-icon-gutter 0 0;
        }
    }
    tr:hover td {
        background: $table-row-hover-bg;
    }
}

ul.pagination {
    li {
        color: $pagination-item-color;
        a {
            background: $pagination-item-bg;
            &:hover {
                background: $pagination-item-hover-bg;
                color: $pagination-item-hover-color;
            }
        }
    }
}

.controlButtons {
    text-align: right;
    .actionButton {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @include responsive-size(height, $table-button-sizes, $breakpoints, min-width, rem-calc);
        @include responsive-size(width, $table-button-sizes, $breakpoints, min-width, rem-calc);
        fill: $table-icon-svg-color;
        svg {
            $icon-heights: getMapMulti($table-button-sizes, $table-button-icon-ratio, rem-calc);
            $icon-widths: getMapMulti($table-button-sizes, $table-button-icon-ratio, rem-calc);
            @include responsive-size(height, $icon-heights);
            @include responsive-size(width, $icon-widths);
        }
    }
}

.rectangleRatioWidth {
    $table-button-sizes-rect: getMapMulti($table-button-sizes, 1.5, rem-calc);
    @include responsive-size(width, $table-button-sizes-rect, $breakpoints, min-width, rem-calc);
    svg {
        $icon-widths: getMapMulti($table-button-sizes-rect, $table-button-icon-ratio, rem-calc);
        @include responsive-size(width, $icon-widths);
    }
}
.rectangleRatioHeight {
    $table-button-sizes-rect: getMapMulti($table-button-sizes, 1.5, rem-calc);
    @include responsive-size(height, $table-button-sizes-rect, $breakpoints, min-width, rem-calc);
    svg {
        $icon-widths: getMapMulti($table-button-sizes-rect, $table-button-icon-ratio, rem-calc);
        @include responsive-size(height, $icon-widths);
    }
}