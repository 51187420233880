@charset 'utf-8';
@import 'foundation-settings';
@import 'foundation';
@import 'motion-ui';

$global-flexbox: true;

@include foundation-global-styles;
//@include foundation-grid;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
//@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
//@include foundation-media-object;
@include foundation-menu;
//@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
//@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

/*
* Funkce i mixins jsou na nejvyšší úrovni -> využití ve všech stylech!
* Soubor global-setting je nad app-setting z důvodu možnosti překrýt individuálním app-settings
* pro jednotlivé instance systému.
*/

// ------- imports DS-FE prototype components -------
@import 'DS-FE/src/assets/scss/prototypes/components/table-responsive';
// --------------------------------------------------

@import 'DS-FE/src/assets/scss/helpers/functions';
@import 'DS-FE/src/assets/scss/helpers/mixins';
@import 'DS-FE/src/assets/scss/helpers/global-settings';
@import 'app-settings';
@import 'fonts';

@import 'DS-FE/src/assets/scss/components/layout/layout';
@import 'DS-FE/src/assets/scss/components/layout/top-bar';
@import 'DS-FE/src/assets/scss/components/layout/content-header';
@import 'DS-FE/src/assets/scss/components/layout/content';
@import 'DS-FE/src/assets/scss/components/layout/nav';
@import 'DS-FE/src/assets/scss/components/layout/aside';
@import 'DS-FE/src/assets/scss/components/layout/footer';
@import 'DS-FE/src/assets/scss/components/layout/flex-grid';

@import 'DS-FE/src/assets/scss/components/hamburgers';
@import 'DS-FE/src/assets/scss/components/datepicker';
@import 'hamburgers/hamburgers';
@import 'foundation-datepicker.scss';
@import 'magnific-popup/src/css/main.scss';
@import 'spinners';

@import 'DS-FE/src/assets/scss/components/icons';
@import 'DS-FE/src/assets/scss/components/loading-spinners';
@import 'DS-FE/src/assets/scss/components/form-elements';
@import 'DS-FE/src/assets/scss/components/typography-elements';
@import 'DS-FE/src/assets/scss/components/dropdown-list';
@import 'DS-FE/src/assets/scss/components/callout';
@import 'DS-FE/src/assets/scss/components/list';
@import 'DS-FE/src/assets/scss/components/login-screen';

@include generateSrollTable();
@include generateFlexSonataTable();

//sonata
@import 'DS-FE/src/assets/scss/components/sonata-table';

@import 'custom/sonata-global';
@import 'custom/sonata-table-grid';
@import 'custom/mixins';
@import 'custom/main';
@import 'custom/dashboards';
@import 'custom/daily-construction-record';
@import 'custom/email-log';
@import 'custom/email';
@import 'custom/job-interview';
@import 'custom/job-order';
@import 'custom/job-order-attendance';
@import 'custom/tool';
@import 'custom/pagination';
@import 'custom/payment';
@import 'custom/person';
@import 'custom/purchaser';
@import 'custom/switch';
@import 'helpers/utils';

@import 'web/vendor/bootstrap-colorpicker/src/sass/_colorpicker.scss';


