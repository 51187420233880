@import '../../settings/components/aside-settings';

/// aside main styles
aside{
    @include responsive-size(width, $aside-sizes);
    @include responsive-size(padding-top, $base-element-gutter-top);
    z-index: z(aside);
    background: $aside-bg;
    box-shadow: 0 1px 6px $base-shadow-color;
    .contentForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: stretch;
        position: relative;

        > .row {
            width: 100%;
        }
        .blockFields {
            flex-direction: column;
            position: relative;
            overflow-y: auto;
            @if $aside-has-form-elements-style {
                label {
                    color: $aside-input-label;
                }
                input {
                    background: $aside-input-bg;
                    box-shadow: $aside-input-shadow;
                    border: $aside-input-border;
                }
            }
        }
        .blockButtonControl {
            flex: 1 0 auto;
            @include responsive-size(padding-top, $base-element-gutter-top);
        }
    }
    .switchWrapper {
        visibility: $aside-bottom-switch-visibility;
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        background: $aside-switchWrapper-bg;
        @include responsive-size(padding, $aside-switchWrapper-padding);
        .switch {
            margin-bottom: 0;
            .switch-paddle {
                vertical-align: top;
                background: $aside-switch-bg;
                &::after {
                    background: $aside-switch-paddle-bg;
                }
            }
            .switch-input:checked ~ .switch-paddle {
                background: $aside-switch-paddle-bg-active;
            }
        }
        small {
            color: $aside-switch-labels-color;
            text-transform: $aside-switch-labels-text-transform;
            padding: $aside-switch-labels-padding;
            @include responsive-size(font-size, $aside-switch-labels-font-sizes);
        }
    }
}