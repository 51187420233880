// hamburger
// ---------
$hamburger-padding-x                       : 0px !default;
$hamburger-padding-y                       : 15px !default;
$hamburger-layer-width                     : 18px !default;
$hamburger-layer-height                    : 2px !default;
$hamburger-layer-spacing                   : 3px !default;
$hamburger-layer-color                     : $white !default;
$hamburger-layer-border-radius             : 1px !default;
$hamburger-hover-opacity                   : 1 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;
$hamburger-types: (
    arrow
) !default;