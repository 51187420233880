@import '../settings/components/loading-spinners-settings';
.#{$loading-spinner-class}{
    @include spinner($loading-spinner-size, border-width $loading-spinner-border-width, border-color $loading-spinner-color, background $loading-spinner-background);
    @include responsive-size(margin-bottom, $loading-spinner-margin-bottom);
    &.secondary{
        border-left-color: $loading-spinner-secondary-color;
        border-bottom-color: $loading-spinner-secondary-color;
    }
    &.black{
        border-left-color: $loading-spinner-black-color;
        border-bottom-color: $loading-spinner-black-color;
    }
    &.white{
        border-left-color: $loading-spinner-white-color;
        border-bottom-color: $loading-spinner-white-color;
    }
    &.large{
        width: $loading-spinner-large-size;
        height: $loading-spinner-large-size;
    }
    &.small{
        width: $loading-spinner-small-size;
        height: $loading-spinner-small-size;
    }
    &.tiny{
        width: $loading-spinner-tiny-size;
        height: $loading-spinner-tiny-size;
    }
    .button &{
        margin: 0 $loading-spinner-in-button-size/2 0 0;
        @include spinner($loading-spinner-in-button-size, border-width $loading-spinner-in-button-border-width, border-color $loading-spinner-in-button-color, background $loading-spinner-in-button-background);
    }
    .button.hollow &{
        @include spinner($loading-spinner-in-button-hollow-size, border-width $loading-spinner-in-button-hollow-border-width, border-color $loading-spinner-in-button-hollow-color, background $loading-spinner-in-button-hollow-background);
    }
}
.loadingPlaceholderWrapper{
    position: relative;
    .#{$loading-spinner-class}{
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 50%;
        margin-left: -($loading-spinner-size/2);
        margin-top: -($loading-spinner-size/2);
        &.large{
            margin-left: -($loading-spinner-large-size/2);
            margin-top: -($loading-spinner-large-size/2);
        }
        &.small{
            margin-left: -($loading-spinner-small-size/2);
            margin-top: -($loading-spinner-small-size/2);
        }
    }
    .#{$loading-button-class} {
        z-index: 1;
        position: relative;
        + .#{$loading-spinner-class} {
            visibility: hidden;
        }
        &.invisible{
            margin-bottom: 0;
            + .#{$loading-spinner-class} {
                visibility: visible;
            }
        }
    }
}