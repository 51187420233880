@import '../settings/components/typography-elements';
$dsfe-header-sizes: (
    xsmall: (
        'h1': 20,
        'h2': 20,
        'h3': 19,
        'h4': 18,
        'h5': 17,
        'h6': 16,
    ),
    medium: (
        'h1': 25,
        'h2': 40,
        'h3': 31,
        'h4': 25,
        'h5': 20,
        'h6': 16,
    ),
    xlarge: (
        'h1': 30,
        'h2': 40,
        'h3': 31,
        'h4': 25,
        'h5': 20,
        'h6': 16,
    ),
);
p {
    @include responsive-size(font-size, $typography-font-sizes);
}

hr {
    @include responsive-size(margin-top, $hr-margin-sizes, $breakpoints, min-width, rem-calc);
    @include responsive-size(margin-bottom, $hr-margin-sizes, $breakpoints, min-width, rem-calc);
    $closest-size : null;
    @if($has-custom-typography-responsive-classes) {
        @each $size, $value in $typography-element-breakpoints {
            $temp-breakpoint: ($size : $value);
            @if map-has-key($hr-margin-sizes, $size) {
                $closest-size: rem-calc(map-get($hr-margin-sizes, $size));
            }
            @include responsive-generator-class-content(collapse-top, $temp-breakpoint) {
                margin-top: 0;
            }
            @include responsive-generator-class-content(collapse-bottom, $temp-breakpoint) {
                margin-bottom: 0;
            }
            @include responsive-generator-class-content(uncollapse-top, $temp-breakpoint) {
                margin-top: $closest-size;
            }
            @include responsive-generator-class-content(uncollapse-bottom, $temp-breakpoint) {
                margin-bottom: $closest-size;
            }
            @include responsive-generator-class-content(collapse, $temp-breakpoint) {
                margin-top: 0;
                margin-bottom: 0;
            }
            @include responsive-generator-class-content(uncollapse, $temp-breakpoint) {
                margin-top: $closest-size;
                margin-bottom: $closest-size;
            }
        }
    }
}

.breakWord {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

code, kbd, pre, samp{
    @include responsive-size(font-size, $typography-font-sizes);
}