$daily-construction-record-table-padding: rem-calc(10 6);

.tableScroll  {
    [name="dailyconstructionrecord-day"] {
        flex-direction: column;
        text-align: center;
        @include td-col(4);
    }
    [name="dailyconstructionrecord-joborder"] {
        flex-direction: column;
        text-align: center;
        @include td-col(4);
    }
    [name="dailyconstructionrecord-record"] {
        flex-direction: column;
        flex: 1 0 auto;
        align-items: flex-start;
    }
    [name="dailyconstructionrecord-lastmodification"] {
        @include td-col(5);
    }
    [name="dailyconstructionrecord-_action"] {
        @include td-col(2);
    }

    @include breakpoint(medium up) {
        [name="dailyconstructionrecord-joborder"] {
            @include td-col(2.5);
        }
        [name="dailyconstructionrecord-day"] {
            @include td-col(2.25);
        }
        [name="dailyconstructionrecord-record"] {
            @include td-col(4);
        }
        [name="dailyconstructionrecord-lastmodification"] {
            @include td-col(3);
        }
        [name="dailyconstructionrecord-_action"] {
            @include td-col(1);
        }
    }

    @include breakpoint(xlarge up) {
        [name="dailyconstructionrecord-joborder"] {
            @include td-col(2);
        }
    }

    @include breakpoint(xxlarge up) {
        [name="dailyconstructionrecord-day"] {
            @include td-col(5);
        }
        [name="dailyconstructionrecord-joborder"] {
            @include td-col(3);
        }
        [name="dailyconstructionrecord-record"] {
            @include td-col(14);
        }
    }
}

.daily-construction-record {
    .listTable {
        width: 100%;
    }
    th, td {
        padding: $daily-construction-record-table-padding;
        flex-direction: column;
        justify-content: center;
    }

    @include breakpoint(xxlarge up) {
        [name="dailyconstructionrecord-day"] {
            @include td-col(3);
        }
    }
}

.daily-construction-record-widget {
    td {
      padding: $daily-construction-record-table-padding;
    }
}