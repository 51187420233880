.tableScroll {
    [name="person-internalnumber"] {
        @include td-col(2);
    }
    [name="person-firstparticipant"] {
        @include td-col(2);
    }
    [name="person-surname"] {
        @include td-col(4);
    }
    [name="person-firstname"] {
        @include td-col(4);
    }

    [name="person-status.name"] {
        width: rem-calc(110);
    }

    [name="person-datecreate"] {
        width: rem-calc(130);
    }

    [name="person-_action"] {
        @include td-col(3);
    }

    [name="person-bonushours"], [name="person-hourssincehourlyrateincrease"] {
        width: rem-calc(130);
    }

    @include breakpoint(medium up) {
        [name="person-internalnumber"] {
            @include td-col(2);
        }
        [name="person-firstparticipant"] {
            @include td-col(1);
        }
        [name="person-surname"] {
            @include td-col(3);
        }
        [name="person-firstname"] {
            @include td-col(3);
        }
        [name="person-_action"] {
            @include td-col(2);
        }
    }

    @include breakpoint(large up) {
        [name="person-surname"] {
            @include td-col(4);
        }
        [name="person-firstname"] {
            @include td-col(4);
        }
    }
}
