@import '../settings/dropdown-settings';

/// dropdown main styles
.dropdown {
    &.menu{
        span{
            line-height: 1.3;
        }
        small{
            text-transform: uppercase;
            display: block;
            font-size: 75%;
            color: $dropdown-small-color;
        }
        strong{
            text-transform: uppercase;
            color: $dropdown-primary-color;
        }
        li{
            a{
                align-items: center;
                color: $dropdown-text-color;
            }
            li{
                svg{
                    width: rem-calc(20);
                    height: rem-calc(20);
                    fill: $dropdown-icons-color;
                }
                &:hover{
                    background: $dropdown-item-hover-background;
                }
            }
        }
        .menu{
            box-shadow: $dropdown-shadow;
            display: none;
        }
        .is-dropdown-submenu{
            //display: none; // foundation menu bug - flexbox layout in foundation breaks this
            display: block;
            opacity: 0;
            visibility: hidden;
            transform: translateY(5px);
            transition: all .3s ease;
            &.js-dropdown-active{
                transform: translateY(0);
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
