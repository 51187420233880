.pagination {
    margin-bottom: 0;
    li {
        margin-bottom: $global-margin;
        &:nth-child(-n+2) {
            display: inline-block;
        }
        &:nth-last-child(-n+2) {
            display: inline-block;
        }
        &.current {
            display: inline-block;
        }
    }

    @include breakpoint(medium up) {
        li {
            display: none;
            &:nth-child(-n+3) {
                display: inline-block;
            }
            &:nth-last-child(-n+3) {
                display: inline-block;
            }
        }
    }

    @include breakpoint(xxlarge up) {
        li {
            display: inline-block;
        }
    }
}