$sonata-table-head-padding-top: (
    xsmall: rem-calc(10)
) !default;
$sonata-table-head-padding-bottom: $sonata-table-head-padding-top !default;
$sonata-table-head-padding-left: (
    xsmall: rem-calc(10)
) !default;

$sonata-table-border-color: $table-border-color !default;
$sonata-thead-color: $table-head-color !default;
$sonata-table-icon-svg-color: $table-icon-svg-color !default;
$sonata-table-icon-svg-width: rem-calc(18px) !default;
$sonata-table-icon-svg-height: rem-calc(18px) !default;
$sonata-button-icon-color: $primary-color !default;
$sonata-button-icon-size: (
    null : rem-calc(16),
    medium : rem-calc(18),
    large: rem-calc(22)
) !default;

$sonata-tag-list-margin: (
    null: rem-calc(0 0 10 0),
    large : rem-calc(0 0 15 0),
) !default;
$sonata-tag-list-tag-margin: (
    null: rem-calc(5 0 5 0),
    large : rem-calc(10 0 10 0),
) !default;
$sonata-tag-list-font-size: (
    null: rem-calc(11),
    medium: rem-calc(13),
) !default;
$sonata-tag-list-tag-padding: (
    null: rem-calc(5 30 5 5),
    medium: rem-calc(5 30 5 5),
) !default;
$sonata-tag-list-tag-close-right: (
    null: rem-calc(7),
    medium: rem-calc(7)
) !default;

$sonata-tag-list-tag-icon-size: rem-calc(14) !default;
$sonata-tag-list-tag-border: 1px solid $medium-gray !default;
$sonata-tag-list-tag-bg: $white !default;
$sonata-tag-list-tag-color: $body-font-color !default;
$sonata-tag-list-tag-icon-color: $primary-color !default;
$sonata-tag-list-tag-hover-bg: $white !default;
$sonata-tag-list-tag-hover-color: $primary-color !default;

$sonata-table-head-weight: normal !default;
$sonata-table-head-bg-active: mix($table-head-bg, $black, 90%) !default;
$sonata-table-head-color-active: $primary-color !default;
$sonata-table-head-border-bottom-active: $primary-color !default;
$sonata-table-head-active-line-height: rem-calc(2px) !default;
$sonata-table-head-border-bottom-offset: -1px !default;
$sonata-table-head-border-left-offset: -1px !default;
$sonata-table-head-border-right-offset: -1px !default;