$table-column-default-size: 250px !default;
$table-style-shadow: none !default;
$table-default-sizes: (
    null: 768px,
    medium: 1250px
) !default;
$table-scroll-limit-width: 2000px !default;
$table-scroll-grid-prefix: "tcol" !default;
$table-pure-scroll-default-className: "tablePureScroll" !default;
$table-hybrid-scroll-default-className: "tableNativeScroll" !default;
$table-hybrid-sonata-scroll-className: "tableScroll" !default;

$table-has-pure-scroll-grid-system: true !default;
$table-has-grid-classes: true  !default;


