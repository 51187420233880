$loading-spinner-class: "loading" !default;
$loading-button-class: "button" !default;
$loading-spinner-size: rem-calc(22) !default;
$loading-spinner-tiny-size: rem-calc(14) !default;
$loading-spinner-small-size: rem-calc(18) !default;
$loading-spinner-large-size: rem-calc(26) !default;
$loading-spinner-border-width: 2px !default;
$loading-spinner-color: $primary-color !default;
$loading-spinner-background: rgba(0, 0, 0, .1) !default;
$loading-spinner-margin-bottom: $base-element-gutter-bottom !default;

//colors
$loading-spinner-secondary-color: $secondary-color !default;
$loading-spinner-black-color: $body-font-color !default;
$loading-spinner-white-color: $white !default;

// inside buttons
$loading-spinner-in-button-size: rem-calc(14) !default;
$loading-spinner-in-button-border-width: $loading-spinner-border-width !default;
$loading-spinner-in-button-color: $white !default;
$loading-spinner-in-button-background: transparent !default;

$loading-spinner-in-button-hollow-size: rem-calc(14) !default;
$loading-spinner-in-button-hollow-border-width: $loading-spinner-border-width !default;
$loading-spinner-in-button-hollow-color: $loading-spinner-color !default;
$loading-spinner-in-button-hollow-background: $loading-spinner-background !default;
