/// Genereuje vizualne rozdelenie priamich potomkov s moznostou nastavenia intervalu
/// @Include prebieha v rodicovskej classe
/// @param {color} $bgColor - hodnota vlastnosti background-color - #fff, #afafaf...
/// @param {String} $selectedElement - element pre ktory ma vlastnost platit. - >div, >.example...
/// @param {Number} $selectNth - nastavenie intervalu pre opakovanie
@mixin add-striped($bgColor, $padding: true, $selectedElement: '*', $selectNth: 2) {
    > #{$selectedElement} {
      @if $padding == true {
        @include responsive-size(padding-top, $base-element-gutter-half);
      }
      &:nth-child(#{$selectNth}n) {
        background-color: $bgColor;
      }
    }
}