h2 {
    color: $secondary-color;
}
h4 {
    color: $secondary-color;
    font-weight: bold;
}

//typography

// top bar
// ---------
$top-bar-bg: $white;
$top-bar-border-style: map-get($foundation-palette, primary);
$top-bar-has-shadow: true;
$top-bar-shadow: 0 0 5px $base-shadow-color;
$top-bar-logo-size: (
    null: 60%,
    medium: 75%
);
$top-bar-title-widths: (
        null: auto,
        small: rem-calc(220),
        xlarge: rem-calc(220),
        xxlarge: rem-calc(240),
);
$title-primary-color: $white;
$title-secondary-color: mix($white, map-get($foundation-palette, primary), 50%);
$title-bg: $white;
$title-bg-shadow: null;
$top-bar-icons-color: $secondary-color;

$dropdown-in-top-bar: true;
$dropdown-in-top-bar-color: $white;
$dropdown-in-top-bar-small-color: $secondary-color;
$dropdown-in-top-bar-primary-color: $primary-color;
$dropdown-in-top-bar-link-padding: (
    null: rem-calc(0 10 0 10),
    medium: rem-calc(0 16 0 16),
);

// content header
// ---------
$content-header-padding: (
    null: rem-calc(getBaseGutter(xsmall) 0 0 0),
    medium: rem-calc(getBaseGutter(medium) 0 0 0),
);
$content-header-h1-font-size: (
    xsmall: rem-calc(19),
    medium: rem-calc(23),
    xlarge: rem-calc(25)
);
$content-header-h1-weight: bold;
// nav
// ---------
$nav-level0-item-border: 1px solid mix($secondary-color, $black, 90%);
$nav-level0-icon-color: $white;
$nav-level0-arrow-color: mix($secondary-color, $white, 70%);
$nav-level0-background: mix($secondary-color, $black, 70%);
$nav-level0-link-color: $white;
$nav-level0-current-color: $white;
$nav-level0-current-bg: mix($secondary-color, $black, 50%);
$nav-level0-current-bg-hover: mix($secondary-color, $black, 60%);
$nav-level1-icon-color: $primary-color;
$nav-level1-link-color:  mix($secondary-color, $white, 70%);
$nav-level1-current-color: $white;
$nav-level1-current-bg-hover: mix($secondary-color, $black, 70%);
$nav-level2-link-color: mix($secondary-color, $white, 80%);
$nav-level2-current-color: $white;

$nav-sizes:(
    null: 100%,
    small: rem-calc(200),
    xlarge: rem-calc(220),
    xxlarge: rem-calc(240),
);


// hamburger
// ---------
$hamburger-layer-color: $secondary-color;

// content
// ---------
$content-header-h1-color: $primary-color;
$content-header-has-shadow: false;
$content-header-border: 1px solid $medium-gray;

// aside
// --------
$aside-bottom-switch-visibility: hidden;
$aside-sizes: (
    null: 100%,
    small: rem-calc(200),
    xlarge: rem-calc(220),
    xxlarge: rem-calc(240),
);

// callout
// ---------
$callout-margin: (
    null: rem-calc(0 0 10 0),
    medium: rem-calc(0 0 20 0),
);

// Close button
// ---------
$close-button-size: 2rem;

// footer
// ---------
$footer-sizes: (
    null: rem-calc(30),
    xsmall: rem-calc(35),
    medium: rem-calc(45),
    large: rem-calc(55),
);
$footer-background: $white;

// form
$label-required-symbol: "*";
$label-required-symbol-color: $body-font-color;
$label-required-symbol-size: $base-font-sizes;
$button-font-weight: bold;
$legend-color: $body-font-color;
$legend-text-transform: uppercase;
$legend-font-weight: 700;
$legend-font-sizes: (
        null: rem-calc(16),je
        medium: rem-calc(18),
        large: rem-calc(20)
);
$legend-margin-bottom: $base-element-gutter-quarter;
$legend-line-height: $header-lineheight;
.form-error {
    color: map-get($foundation-palette, alert);
}

//icons
$icon-color: $secondary-color;

// Header icons
$header-icons-size: rem-calc(18);
$header-icons-padding-left: rem-calc(30);


//list
$table-body-color: $body-font-color;
$table-head-color: $body-font-color;
$table-head-bg: $white;
$table-grid-cols: 24;
$table-sizes: (
    null: 768px,
    medium: 1200px,
    large: 1600px
);

// Tag List
$sonata-tag-list-margin: (
    null: rem-calc(10),
    large : rem-calc(15),
);
$sonata-tag-list-font-size: rem-calc(11);

$sonata-table-head-weight: bold;
$sonata-table-head-bg-active: mix($table-head-bg, $black, 98%);

// custom project colors

$customColorsPalete: (
    Yellow: #ffd738,
    Green: #008000,
    Red: #ff0000,
    Black: #000,
    Purple: #8B4789,
    Darkblue: #62A3CF,
    Lightblue: #CAE6E6,
    Orange: #f36717,
    Ore: #8A1D1D,
    White: #FFFFFF,
    Brown: #3A2E10,
    Blue: #74bfdd
);

//Dashboard settings
$elementStyleColor: desaturate(lighten($secondary-color, 30%), 10%);

$dropdown-menu-anchor-color: #555;
$dropdown-menu-border-color: rgba(215,206,185,0.5);
$dropdown-menu-background-color: #fefefe;

$transition-time: 300ms;
$transition-type: ease;

$breakpoint-table-behavior: large;

$d-widget-header-font-size: (
        null: rem-calc(16),
        medium : rem-calc(18),
        large: rem-calc(22)
);
