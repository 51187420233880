@import "../settings/table-responsive-settings";

@mixin responsiveTableBehavior($type: "native") {
    @if $type == "native" {
        width: 100%;
        @include responsive-size(margin-bottom, $base-element-gutter-bottom);
    } @else if ($type == "flex") {
        vertical-align: top;
        position: relative;
        margin-bottom: 0;
        width: auto;
        @include isSet($table-style-shadow) {
            box-shadow: none;
        }
    }
}

@mixin tableRowBehavior($type: "native") {
    @if $type == "native" {
        white-space: normal;
        display: table-row;
    } @else if ($type == "flex") {
        white-space: nowrap;
        font-size: 0;
        display: flex;
        justify-content: stretch;
    }
}

@mixin tableCellBehavior($type: "native", $table-column-size: $table-column-default-size) {
    @if $type == "native" {
        display: table-cell;
        width: auto;
    } @else if ($type == "flex") {
        width: $table-column-size;
        display: inline-flex;
        white-space: normal;
        align-items: center;
    }
}

@mixin tableFlex($table-default-column-size: $table-column-default-size, $margin-bottom-sizes: $base-element-gutter-bottom,
                 $table-style-shadow: $table-style-shadow) {
    overflow-x: auto;
    @include responsive-size(margin-bottom, $margin-bottom-sizes);
    @include isSet($table-style-shadow) {
        box-shadow: $table-style-shadow;
    }
    .responsive-table-grid {
        @include responsiveTableBehavior("flex");
    }
    tr {
        @include tableRowBehavior("flex");
    }
    td, th {
        @include tableCellBehavior("flex", $table-default-column-size)
    }
    .controlButtons{
        flex: 0 0 100%;
    }
}

@mixin tableNative($table-style-shadow: $table-style-shadow, $breakpoint-table-scroll: "") {
    margin-bottom: 0;
    width: 100%;
    @if $breakpoint-table-scroll != "" {
        @include breakpoint(#{$breakpoint-table-scroll} up) {
            @include table-scroll;
        }
    } @else {
        @include table-scroll;
    }
    .responsive-table-grid {
        @include responsiveTableBehavior("native");
    }
    @include isSet($table-style-shadow) {
        box-shadow: none;
        .responsive-table-grid {
            box-shadow: $table-style-shadow;
        }
    }
    tr {
        @include tableRowBehavior("native");
    }
    td, th {
        @include tableCellBehavior("native")
    }
}

@mixin createSonataTableWrapper($className, $table-sizes, $scroll-table-default-column-size: $table-column-default-size,
                     $scroll-margin-bottom-sizes: $base-element-gutter-bottom, $scroll-table-style-shadow: $table-style-shadow,
                     $table-style-shadow: $table-style-shadow) {
        @include tableFlex($scroll-table-default-column-size, $scroll-margin-bottom-sizes, $scroll-table-style-shadow);
        $maxWidth: last(map-values($table-sizes));
        @include breakpoint($maxWidth up) {
            @include tableNative($table-style-shadow);
        }
}

/// Generuje šírku td v tabulke na základe zadaného pomeru vychádzajúceho z nastaveného počtu stĺpcov v Gride
/// @param {Number} $col - nastavenie pomeru
/// @param {Number} $max-col - nastavený počet stĺpcov v gride
/// @param {Map} $s-table-sizes - hodnoty šírky tabuľky v daných breakpointoch
@mixin td-col($col, $max-col: $table-grid-cols, $s-table-sizes: $table-sizes) {
    $list-table-sizes: map-values($s-table-sizes);
    $minSize: #{nth($list-table-sizes, 1) - 1};
    @media screen and (max-width: $minSize) {
        width: nth($list-table-sizes, 1)  / $max-col * $col;
    }
    @for $i from 1 to length($list-table-sizes) {
        $old-index: $i + 1;
        $minSize: #{nth($list-table-sizes, $i) - 1};
        $maxSize: nth($list-table-sizes, $old-index);
        @media screen and (min-width: $minSize) and (max-width: $maxSize) {
            width: nth($list-table-sizes, $old-index) / $max-col * $col;
        }
    }
    $maxWidth: last(map-values($s-table-sizes));
    @include breakpoint ($maxWidth up) {
        width: percentage($col/$max-col);
    }
}

// Grid systém scroll tabulky
// 2 varianty použiti neposlu breakpoint je scroll porad, poslu breakpoint a od neho tabulka scrollovatelna neni..
@mixin createScrollTableWrapper($breakpoint-table-behavior, $table-style-shadow: $table-style-shadow) {
    @include tableFlex();
    @if ($breakpoint-table-behavior != "") {
        @include breakpoint($breakpoint-table-behavior up) {
            @include tableNative($table-style-shadow);
        }
    }

}

@function getRatioWidthColumn($column-count, $max-col, $base-width: "") {
    @if $base-width != "" {
        @return rem-calc($base-width / $max-col * $column-count);
    }
    @return $column-count/$max-col * 100%
}
@mixin table-breakpoints-generator($breakpoint-index, $column-count, $breakpoints-grid-width, $breakpoints-grid-keys, $breakpoint-table-behavior-index, $tableIsScrollable, $max-col) {
    @for $k from $breakpoint-index + 1 through length($breakpoints-grid-width) {
        $actual-width: nth($breakpoints-grid-width, $k);
        $temp-breakpoint: nth($breakpoints-grid-keys, $k - 1);
        @if $breakpoint-table-behavior-index != null {
            @if ($breakpoint-table-behavior-index <= $k - 1) {
                $tableIsScrollable: false;
            }
        }
        @include breakpoint (#{$temp-breakpoint} up) {
            width: if($tableIsScrollable,
                getRatioWidthColumn($column-count, $max-col, $actual-width),
                getRatioWidthColumn($column-count, $max-col));
        }
    }
}

@mixin tcol-class-generator($limit-width: 2000px, $table-breakpoint-behavior: large, $max-col: 24, $table-grid-prefix: "tcol" , $table-grid-breakpoints: $breakpoints) {
    $breakpoints-grid-width: append(to-list($table-grid-breakpoints, values), $limit-width);
    $breakpoints-grid-keys: map-keys($table-grid-breakpoints);
    $breakpoint-table-behavior-index: index($breakpoints-grid-keys, $table-breakpoint-behavior);
    @for $i from 1 through length($breakpoints-grid-keys) {
        $actual-breakpoint: nth($breakpoints-grid-keys, $i);
        @for $j from 1 through $max-col {
            $tableIsScrollable: true;
            .#{$table-grid-prefix}-#{$actual-breakpoint}-#{$j} {
                @include table-breakpoints-generator($i, $j, $breakpoints-grid-width, $breakpoints-grid-keys, $breakpoint-table-behavior-index, $tableIsScrollable, $max-col);
            }
        }
    }
}
//  table grid system
@mixin td-col-grid-sizes($col, $limit-width: 2000px, $table-breakpoint-behavior: large, $max-col: 24, $table-grid-breakpoints: $breakpoints) {
    $breakpoints-grid-width: append(to-list($table-grid-breakpoints, values), $limit-width);
    $breakpoints-grid-keys: map-keys($table-grid-breakpoints);
    $breakpoint-table-behavior-index: index($breakpoints-grid-keys, $table-breakpoint-behavior);
    @for $i from 1 through length($breakpoints-grid-keys) {
        $actual-breakpoint: nth($breakpoints-grid-keys, $i);
        $tableIsScrollable: true;
        @include table-breakpoints-generator($i, $col, $breakpoints-grid-width, $breakpoints-grid-keys, $breakpoint-table-behavior-index, $tableIsScrollable, $max-col);
    }
}

@mixin generateSrollTable($tcol-grid-generator: $table-has-grid-classes, $default-className: $table-hybrid-scroll-default-className, $table-scroll-prefix: $table-scroll-grid-prefix, $limit-width: $table-scroll-limit-width, $breakpoint-table-behavior: large, $max-col: 24, $s-breakpoints: $breakpoints,  $table-style-shadow: $table-style-shadow) {
    $class-context: if($default-className != "", str-insert($default-className, ".", 1), "&");
    #{$class-context} {
        @include createScrollTableWrapper($breakpoint-table-behavior, $table-style-shadow);
        @if ($tcol-grid-generator == true) {
            @include tcol-class-generator($limit-width, $breakpoint-table-behavior, $max-col, $table-scroll-prefix, $s-breakpoints);
        }
    }
}

@mixin generateFlexSonataTable(
    $default-className: "tableScroll",
    $table-sizes: $table-default-sizes,
    $scroll-table-default-column-size: $table-column-default-size,
    $scroll-margin-bottom-sizes: $base-element-gutter-bottom,
    $scroll-table-style-shadow: $table-style-shadow,
    $table-native-style-shadow: $table-style-shadow
) {
    $class-context: if($default-className != "", str-insert($default-className, ".", 1), "&");
    #{$class-context} {
        @include createSonataTableWrapper($default-className, $table-sizes, $scroll-table-default-column-size, $scroll-margin-bottom-sizes,
            $scroll-table-style-shadow, $table-native-style-shadow);
    }
}
