/// Generuje vlastnosti na základe šírkových breakpointov (mobile first) obsahujúce vlastnosti podla predaných parametroch.
/// @param {String} $property - názov vlastnosti, ktorá sa bude generovať na rôznych breakpointoch  (font-size, margin)
/// @param {Map} $s-map - pole s hodnotami danej vlastnosti. 
/// @param {Map} $s-breakpoints - hodnoty breakpointu media query.
/// @param {String} $design-philosophy - filozofia dizajnu (min-width, max-width)
@mixin responsive-size($property, $s-map, $s-breakpoints: $breakpoints, $design-philosophy: min-width, $rem-calc : null) {
    @if type-of($s-map) == 'map' {
        @if map-has-key($s-map, breakpoints) and map-has-key($s-map, sizes) {
            $s-breakpoints: map_get($s-map, breakpoints);
            @if map-has-key($s-map, philosophy) {
                $design-philosophy: map-get($s-map, philosophy)
            }
            $s-map: map_get($s-map, sizes);
        }
        @each $s-breakpoint, $s-size in $s-map {
            @if type-of($s-size) == 'number' and is-absolute-length($s-size) {
                $s-size: rem-calc($s-size);
            }
            @if $s-breakpoint == null {
                #{$property}: $s-size;
            }
            @else {
                @if map-has-key($s-breakpoints, $s-breakpoint) {
                    $s-breakpoint: map-get($s-breakpoints, $s-breakpoint);
                }
                @media screen and (#{$design-philosophy}: $s-breakpoint) {
                    #{$property}: $s-size;
                }
            }
        }
    }
}

/// Generuje vysuvacie menu
/// @param {String} $position - názov vlastnosti, pre umiestnenie menu ("left", "right")
/// @param {String} $break - nastavenie breakpointu v ktorom sa menu zobrazí ("xxlarge", "xsmall")
/// @param {String} $mobileToggleClass - výber triedy na ktoru referuje funkcia (toggle) pre skrytie menu
/// @param {String} $desktopToggleClass - výber triedy na ktoru referuje funkcia (toggle) pre zobrazenie menu
@mixin offcanvas($position: "left", $break: "large", $mobileToggleClass: ".toggleLeftSidebarMobile", $desktopToggleClass: ".toggleLeftSidebarDesktop") {
    #{$position}: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    backface-visibility: hidden;
    transition: transform .3s ease;
    @if $position == "left" {
        transform: translate3d(-100%, 0, 0);
    } @else {
        transform: translate3d(100%, 0, 0);
    }
    &#{$mobileToggleClass}{
        transform: translate3d(0, 0, 0);
    }
    @include breakpoint(#{$break} up){
        transform: translate3d(0, 0, 0);
        &#{$mobileToggleClass}{
            transform: translate3d(0, 0, 0);
        }
        #{$desktopToggleClass} &{
            @if $position == "left" {
                transform: translate3d(-100%, 0, 0) !important;
            } @else {
                transform: translate3d(100%, 0, 0) !important;
            }
        }
    }
}
/// Generuje třídy podle šířkových breakpointů (mobile first) obsahující vlastnosti dle předaných parametrů.
/// @param {String - kľúč mapy} $key
@mixin zindex-manager($key : null) {
  @if ($key != null) {
    z-index: map-get($zindex-values, $key);
  }
}
/// Vykoná daný obsah mixinu, ak je nastavená premenná v parametri
@mixin isSet($value : null) {
    @if $value != "" OR $value != null OR $value != nan {
        @content;
    }
}

/// Generuje třídy podle šířkových breakpointů (mobile first) obsahující vlastnosti dle předaných parametrů.
/// @param {String} $property-name - název vlastnosti pro kterou se generují breakpoints (small-xxx, medium-xxx)
/// @param {Map} $s-breakpoints - hodnoty breakpointu media query.
@mixin responsive-generator-class-content($property-name, $s-breakpoints: $breakpoints) {
    @each $size, $value in $s-breakpoints {
        &.#{$size}-#{$property-name} {
            @media screen and (#{"min-width"}: $value) {
                @content;
            }
        }
    }
}

/// Generuje/edituje prvky menu, ktoré sa na základe breakpointov prispôsobujú
/// @param {Number} $font-size
/// @param {Number} $side-link-padding
/// @param {Number} $side-link-padding
/// @param {Color} $nav-link-color
/// @param {Color} $nav-current-bg-hover
/// @param {Color} $nav-current-color
@mixin nav-list-items($font-size, $side-link-padding, $nav-link-color, $nav-current-bg-hover, $nav-current-color) {
    @include responsive-size(font-size, $font-size);
    a:not(.button), strong{
        @include responsive-size(padding, $side-link-padding);
        color: $nav-link-color;
        font-weight: normal;
    }
    a:not(.button){
        &:hover, &:focus {
            background: $nav-current-bg-hover;
        }
    }
    &.current{
        a:not(.button), strong {
            font-weight: normal;
            color: $nav-current-color;
            &:hover, &:focus {
                background: $nav-current-bg-hover;
            }
        }
    }
}
/// Genereuje vizualne rozdelenie priamich potomkov s moznostou nastavenia intervalu
/// @Include prebieha v rodicovskej classe
/// @param {color} $bgColor - hodnota vlastnosti background-color - #fff, #afafaf...
/// @param {String} $selectedElement - element pre ktory ma vlastnost platit. - >div, >.example...
/// @param {Number} $selectNth - nastavenie intervalu pre opakovanie
@mixin add-striped($bgColor, $selectedElement: '*', $selectNth: 2) {
    > #{$selectedElement} {
        &:nth-child(#{$selectNth}n) {
            background-color: $bgColor;
        }
    }
}
/// Generuje vypocitane rozmery 16:9/4:3...
/// @param {Number} $width sirka elementu
/// @param {Number} $height vyska elementu
/// @param {String} $property vlastnost, ktora ma byt definovana
@mixin aspectRatio($width,$height, $property: 'padding-top'){
    #{$property}: ($height / $width) * 100%;
}

@mixin poly-fluid-sizing($property, $map) {
    // Get the number of provided breakpoints
    $length: length(map-keys($map));

    // Error if the number of breakpoints is < 2
    @if ($length < 2) {
        @error "poly-fluid-sizing() $map requires at least values"
    }

    // Sort the map by viewport width (key)
    $map: map-sort($map);
    $keys: map-keys($map);

    // Minimum size
    #{$property}: map-get($map, nth($keys,1));

    // Interpolated size through breakpoints
    @for $i from 1 through ($length - 1) {
        @media (min-width:nth($keys,$i)) {
            $value1: map-get($map, nth($keys,$i));
            $value2: map-get($map, nth($keys,($i + 1)));
            // If values are not equal, perform linear interpolation
            @if ($value1 != $value2) {
                #{$property}: linear-interpolation((nth($keys,$i): $value1, nth($keys,($i+1)): $value2));
            } @else {
                #{$property}: $value1;
            }
        }
    }

    // Maxmimum size
    @media (min-width:nth($keys,$length)) {
        #{$property}: map-get($map, nth($keys,$length));
    }
}

/// Generuje animáciu pre mixinu stripesBackgroundImage
/// @param {String} $duration Dĺžka animácie
/// @param {Number} $progressBar-gradient-size Veľkosť pruhov pre background-size
@mixin movingAnimationStripes($duration: 1s, $progressBar-gradient-size: rem-calc(18 18)) {
  animation: move $duration linear infinite;
  @keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: $progressBar-gradient-size;
    }
  }
}

/// Generuje pruhované pozadie
/// @param {Number} $gradient-background-size Veľkosť pruhov
/// @param {String} $gradient-angle Uhol naklonenia vygenerovaných pruhov
@mixin stripesBackgroundImage($gradient-background-size, $gradient-angle: 135deg) {
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(
        $gradient-angle,
        rgba(255, 255, 255, .2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, .2) 50%,
        rgba(255, 255, 255, .2) 75%,
        transparent 75%,
        transparent);
    background-size: $gradient-background-size;
  }
}